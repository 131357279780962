export class LoginGov {
  stateGuid: string;
  nonceGuid: string;
  acrValueUrl: string;
  logOutResponseUrl: string;
  clientUrl: string;
  redirectUrl: string;
  acrValues: string;
  clientId: string;
  prompt: string;
  responseType: string;
  scope: string;
  state: string;
  nonce: string;
}
