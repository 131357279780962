import { Component, Inject, Input } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Observable, Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService, LoginModel } from '../_services/authentication.service';
import { parse } from 'querystring';
import { User } from '../account/user';


function dateErrorValidator(control: AbstractControl): { [key: string]: boolean } | null {
  let validFormat = new RegExp("^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{4})*$");  let date: string[] = control.value.toString().split("/", 3);  if (!validFormat.test(control.value.toString())) {    return { 'dateError': true };
  }  else {    switch (Number(date[0])) {
      case 1: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 2: {
        if (Number(date[1]) == 29) {
          if ((Number(date[2]) % 4 == 0 && Number(date[2]) % 100 != 0) || (Number(date[2]) % 400 == 0)) {
            return null;
          }
          else {
            return { 'dateError': true };
          }
        }
        else {
          if (Number(date[1]) >= 1 && Number(date[1]) <= 28) {
            return null;
          }
          else {
            return { 'dateError': true };
          }
        }
      }
      case 3: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 4: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 30) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 5: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 6: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 30) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 7: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 8: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 9: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 30) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 10: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 11: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 30) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 12: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
    }  }
  return null;
}

interface withdrawal {
  driverState: string;
  driverLicenseNumber: string;
  withdrawalJurisdictionCode: string;
  withdrawalJurisdictionLocator: string;
  withdrawalEffectiveDate: string;
  withdrawalActionTypeCode: string;
  withdrawalAcdCode: string;
  withdrawalReasonReference: string;
  withdrawalId: string;
  withdrawalEligibilityDate: string;
  withdrawalReinstateDate: string;
  withdrawalBasisCode: string;
  withdrawalDueProcStatCode: string;
  withdrawalExtentCode: string;
  withdrawalCategory: string;
  withdrawalDuration: string;
  withdrawalReinstLetterSentDate: string;
  link: string;
  withdrawalUserId: string;
  withdrawalDatePosted: string;
  withdrawalDatePostedDisplay: string;
}

@Component({
  selector: 'app-withdrawal-component',
  templateUrl: './withdrawal.component.html',
  styleUrls: ['./withdrawal.component.css']

})
export class WithdrawalComponent {
  minDate = new Date(1000, 0, 1);
  maxStartDate = new Date();
  setFromDate = new Date();
  setToDate = new Date();
  private loginState: string;

  public withdrawalListObservable: Observable<withdrawal[]>;
  displayedWithdrawalData: string[];
  datePattern = "^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{4})*$";


  constructor(_http: HttpClient, @Inject('BASE_URL') _baseUrl: string, private spinner: NgxSpinnerService, private authService: AuthenticationService) {
    this.baseUrl = _baseUrl;
    this.http = _http;
    this.authService.currentUser.subscribe(g => this.loginState = g.state);
    this.setFromDate.setDate(new Date().getDate() - 5);
    this.setToDate.setDate(new Date().getDate());
    this.startDateFormControl.setValue(this.setFromDate.toLocaleDateString("en-US"), { emitEvent: false, onlySelf: true });
    this.endDateFormControl.setValue(this.setToDate.toLocaleDateString("en-US"), { emitEvent: false, onlySelf: true });
    this.startDateform.valueChanges.subscribe(g => {
      this.startDateFormControl.setValue(g.toLocaleDateString("en-US"), { emitEvent: false, onlySelf: true });

    });
    this.endDateform.valueChanges.subscribe(g => {
      this.endDateFormControl.setValue(g.toLocaleDateString("en-US"), { emitEvent: false, onlySelf: true });

    });

    this.startDateFormControl.valueChanges.subscribe(g => {
      if (this.startDateFormControl.valid) {
        this.startDateform.patchValue(new Date(g), { emitEvent: false, onlySelf: true });
      }
      else {
        this.startDateform.patchValue('', { emitEvent: false, onlySelf: true });
      }
    });
    this.endDateFormControl.valueChanges.subscribe(g => {
      if (this.endDateFormControl.valid) {
        this.endDateform.patchValue(new Date(g), { emitEvent: false, onlySelf: true });
      }
      else {
        this.endDateform.patchValue('', { emitEvent: false, onlySelf: true });
      }
    });
  }
  http: HttpClient;
  baseUrl: string;
  errorMessage: string = "";
  public error: boolean;
  userInfo: Observable<User>;
  public getWithdrawals() {
    if (this.endDateFormControl.invalid || this.startDateFormControl.invalid) {
      this.endDateFormControl.markAsTouched();
      this.startDateFormControl.markAsTouched();
    }
    else if (new Date(this.endDateFormControl.value) < new Date(this.startDateFormControl.value)) {
      this.error = true;
      this.errorMessage = "The End Date must be equal to or greater than the Start Date.";
    }
    else {
      this.spinner.show();
      this.authService.currentUser.subscribe(g => {
        if (g != null) {
          var Account = new LoginModel();
          Account.Password = "xxxxxxxxx";
          Account.UserID = g.userID;
          this.userInfo = this.http.post<User>(this.baseUrl + 'login/Account/RefreshToken', Account);
          this.userInfo.subscribe(g => {
            localStorage.removeItem('currentUser');
            localStorage.setItem('currentUser', JSON.stringify(g));
          });
        }
      });
      this.displayedWithdrawalData = ['Actions', 'driverState', 'driverLicenseNumber', 'withdrawalEffectiveDate', 'withdrawalEligibilityDate', 'withdrawalReinstateDate', 'withdrawalJurisdictionCode', 'withdrawalId', 'link', 'withdrawalAcdCode', 'action', 'basis', 'withdrawalExtentCode', 'dueProcStatus', 'withdrawalLocatorNumber', 'stateNativeCode', 'withdrawalUserId', 'withdrawalDatePosted'];
      let Params = new HttpParams();
      Params = Params.append("state", this.loginState);
      Params = Params.append("startdate", this.startDateFormControl.value);
      Params = Params.append("enddate", this.endDateFormControl.value);
      this.withdrawalListObservable = this.http.get<withdrawal[]>(this.baseUrl + 'api/Data/Withdrawals', { params: Params });
      this.withdrawalListObservable.subscribe(g => {
        if (g.length <= 0) {
          this.error = true;
          this.errorMessage = "No Records to show.";
        }
        else {
          this.error = false;
        }
      });
      this.spinner.hide();
    }
  }

  startDateFormControl = new FormControl('', [
    Validators.required, dateErrorValidator
  ]);
  endDateFormControl = new FormControl('', [
    Validators.required, dateErrorValidator
  ]);
  startDateform = new FormControl({ value: this.setFromDate, disabled: false });
  endDateform = new FormControl({ value: this.setToDate, disabled: false });
}
