import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from "@angular/router";
import { UIDataService } from '../driverDetail/uiData.service';
import { LoginModel, AuthenticationService } from '../_services/authentication.service';
import { User } from '../account/user';
import { SessionService } from '../_services/session.service';
import { Observable } from 'rxjs';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-faq-component',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})


export class FAQComponent implements OnInit {
  _http: HttpClient;
  _baseUrl: string;
  userInfo: Observable<User>;

  constructor(private spinner: NgxSpinnerService, http: HttpClient, private router: Router, private idleTimeoutSvc: SessionService, private authService: AuthenticationService, @Inject('BASE_URL') baseUrl: string, private DataService: UIDataService) {
    this._http = http;
    this._baseUrl = baseUrl;

  }

  ngOnInit() {

    this.authService.currentUser.subscribe(g => {
      if (g != null) {

        let Account = new LoginModel();
        Account.Password = "xxxxxxxxx";
        Account.UserID = g.userID;
        this.userInfo = this._http.post<User>(this._baseUrl + 'login/Account/RefreshToken', Account);
        this.userInfo.subscribe(g => {
          localStorage.removeItem('currentUser');
          localStorage.setItem('currentUser', JSON.stringify(g));
          this.idleTimeoutSvc.resetTimer();
        });
      }
    });
  }
}
