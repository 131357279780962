import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from "@angular/router";
import { UIDataService } from '../driverDetail/uiData.service';
import { LoginModel, AuthenticationService } from '../_services/authentication.service';
import { User } from '../account/user';
import { SessionService } from '../_services/session.service';
import { Observable } from 'rxjs';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';

function dateErrorValidator(control: AbstractControl): { [key: string]: boolean } | null {
  let validFormat = new RegExp("^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{4})*$");
  let date: string[] = control.value.toString().split("/", 3);
  if (!validFormat.test(control.value.toString())) {
    return { 'dateError': true };
  }
  else {
    switch (Number(date[0])) {
      case 1: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 2: {
        if (Number(date[1]) == 29) {
          if ((Number(date[2]) % 4 == 0 && Number(date[2]) % 100 != 0) || (Number(date[2]) % 400 == 0)) {
            return null;
          }
          else {
            return { 'dateError': true };
          }
        }
        else {
          if (Number(date[1]) >= 1 && Number(date[1]) <= 28) {
            return null;
          }
          else {
            return { 'dateError': true };
          }
        }
      }
      case 3: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 4: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 30) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 5: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 6: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 30) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 7: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 8: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 9: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 30) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 10: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 11: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 30) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 12: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
    }
  }
  return null;
}

interface Country {
  jurisdictionCode: string;
  jurisdictionDesc: string;
  type: string;
}

interface ProvinceGroups {
  jurisdiction_code: string;
  jurisdiction_desc: string;
  jurisdiction_group: string;
  is_active: number;
  action_date: Date;
  action_type: string;
  action_user: string;
}

@Component({
  selector: 'app-canada-component',
  templateUrl: './canada.component.html',
  styleUrls: ['./canada.component.css']
})
  
export class CanadaComponent implements OnInit{ 
  public provinces: ProvinceGroups[];
  public driverProvince: ProvinceGroups[];
  public TypeOfCountry: CountryType[] = [{ value: "CN", Text: "Canada" }];

  dlCon: string = "";
  dlState: string = "";
  dob: string = "";
  dobSelected: string;
  selected: string = "CN";
  countryType: string;
  Country: string = "CN";
  _http: HttpClient;
  _baseUrl: string;
  userInfo: Observable<User>;

  constructor(private spinner: NgxSpinnerService,http: HttpClient, private router: Router, private idleTimeoutSvc: SessionService, private authService: AuthenticationService, @Inject('BASE_URL') baseUrl: string, private DataService: UIDataService) {
    this._http = http;
    this._baseUrl = baseUrl;
    http.get<ProvinceGroups[]>(baseUrl + 'api/Data/GetProvinceCodes/').subscribe(result => {
      this.provinces = result;
    }, error => console.error(error));   
   
    this.dlNo.valueChanges.subscribe(g => {
      this.dlNo.setValue(g.toString().toUpperCase(), { emitEvent: false, onlySelf: true });
    });

    this.DOBForm.valueChanges.subscribe(g => {
      this.DOBFormControl.setValue(g.toLocaleDateString("en-US"), { emitEvent: false, onlySelf: true });
    });
    this.DOBFormControl.valueChanges.subscribe(g => {
      if (this.DOBFormControl.valid) {
        this.DOBForm.patchValue(new Date(g), { emitEvent: false, onlySelf: true });
      }
      else {
        this.DOBForm.patchValue('', { emitEvent: false, onlySelf: true });
      }
    });
  }

  ngOnInit() {

    this.authService.currentUser.subscribe(g => {
      if (g != null) {

        let Account = new LoginModel();
        Account.Password = "xxxxxxxxx";
        Account.UserID = g.userID;
        this.userInfo = this._http.post<User>(this._baseUrl + 'login/Account/RefreshToken', Account);
        this.userInfo.subscribe(g => {
          localStorage.removeItem('currentUser');
          localStorage.setItem('currentUser', JSON.stringify(g));
          this.idleTimeoutSvc.resetTimer();
        });
      }
    });
  }

  public getCanadaQuery() {
     /*     
      * Note: Because it is possible to have a driver with only a last name, the system cannot make first name required.
      * However, if a first name is on the record, Canada will not release the data unless first name is on the query
      * and matches the data on the provincial record.
    */
    
    if (this.provinceTypeform.valid && this.dlNo.valid && this.LastName.valid && this.DOBFormControl.valid) {
     /*format dbo*/
      var dobSelected = this.DOBFormControl.value;
      var re = /\//gi;
      this.dob = dobSelected.replace(re, '-');

      var firstName = this.FirstName.value;
      var myurl = "";

      this.dlState = this.provinceTypeform.value;
      
      /*goto canada detail page.ts*/
      if (firstName == "") {    
        firstName = "undefined";
      }

      myurl = `${"/canadaDetail"}/${this.dlState}/${this.dlNo.value}/${this.LastName.value}/${firstName}/${this.dob}`;

      this.router.navigateByUrl(myurl);
    }
    else
    {     
      this.provinceTypeform.markAsTouched();
      this.dlNo.markAsTouched();
      this.LastName.markAsTouched();
      /*this.FirstName.markAsTouched();*/
      this.DOBFormControl.markAsTouched();
    }   
  }

  public FilterProvinces() {
    this.provinces = this.provinces.filter((item) => {
        return item.jurisdiction_group === this.countryType
       });
    if (this.provinces.length > 0) {
      this.Country = this.provinces[0].jurisdiction_code;
    }
    else {
      this.provinceform.reset();
    }
  }

  dlNo = new FormControl('', { updateOn: 'blur', validators: Validators.required });
  provinceform = new FormControl('', [Validators.required]);
  provinceTypeform = new FormControl('', [Validators.required]);
  LastName = new FormControl('', [Validators.required]);
  FirstName = new FormControl('', [Validators.required]);
  DOBFormControl = new FormControl('', [Validators.required, dateErrorValidator]);
  DOBForm = new FormControl('', []);
  QueryFormButton = new FormControl('', { validators: Validators.required });

  public DefaultValue() {
    if (this.driverProvince.length > 0) {
      return this.provinces[0].jurisdiction_desc;
    }
    else {
      return "";
    }
  }
}


export class CountryType {
  value: string;
  Text: string;
}
