import { Component, Inject, ViewChild, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { state } from '@angular/animations';
import { MatAccordion, fadeInContent } from '@angular/material';
import { Location } from '@angular/common';
import { UIDataService } from '../../driverDetail/uiData.service';
import { Observable, Subscription } from 'rxjs';
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { DriverDetailInformation, conviction, withdrawal, GeneralInfo, ResidenceInfo, LicenseDetail, MedicalDetail } from '../../driverDetail/DriverDetailInformation';
import { ActivatedRoute, Router } from '@angular/router';
import { UserAccountService } from '../../account/userAccount.service';
import { NgxSpinnerService } from 'ngx-spinner';

import { MessageDialogComponent } from '../../convictionReview/conReview.component';
import { share } from 'rxjs/operators';
import { AuthenticationService, LoginModel } from '../../_services/authentication.service';
import { Suffix } from '.././canDriverRecordResponse/canAddDriverInfo.component';
import { MatInputModule } from '@angular/material/input';
import { User } from '../../account/user';
import { SessionService } from '../../_services/session.service';

function dateErrorValidator(control: AbstractControl): { [key: string]: boolean } | null {
  let validFormat = new RegExp("^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{4})*$");
  let date: string[] = control.value.toString().split("/", 3);
  if (!validFormat.test(control.value.toString())) {
    return { 'dateError': true };
  }
  else {
    switch (Number(date[0])) {
      case 1: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 2: {
        if (Number(date[1]) == 29) {
          if ((Number(date[2]) % 4 == 0 && Number(date[2]) % 100 != 0) || (Number(date[2]) % 400 == 0)) {
            return null;
          }
          else {
            return { 'dateError': true };
          }
        }
        else {
          if (Number(date[1]) >= 1 && Number(date[1]) <= 28) {
            return null;
          }
          else {
            return { 'dateError': true };
          }
        }
      }
      case 3: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 4: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 30) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 5: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 6: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 30) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 7: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 8: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 9: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 30) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 10: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 11: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 30) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 12: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
    }
  }
  return null;
}

export class State {
  jurisdictionCode: string;
  jurisdictionDesc: string;
  jurisdictionGroup: string;
}

export class CountryType {
  value: string;
  Text: string;
}
interface Country {
  jurisdictionCode: string;
  jurisdictionDesc: string;
  type: string;
}

interface ProvinceGroups {
  jurisdiction_code: string;
  jurisdiction_desc: string;
  jurisdiction_group: string;
  is_active: number;
  action_date: Date;
  action_type: string;
  action_user: string;
}

@Component({
  selector: 'app-canDriverResponseRecord-component',
  templateUrl: './canDriverResponseRecord.component.html'
})

export class canDriverResponseRecordComponent {
  public stateDropDown: boolean = false;
  public ErrorMessage: string;
  public driverInformation = new DriverDetailInformation();
  public isDisabled: boolean;
  public driverLastName: string = "";
  public provinces: ProvinceGroups[];
  public driverProvince: ProvinceGroups[];
  public CountriesObservable: Observable<Country[]>;
  public CountrystatesObservable: Observable<State[]>;
  public Countrystates: State[];
  public TypeOfCountry: CountryType[] = [{ value: "CN", Text: "Canada" }];
  DriverLicense: string;
  DriverStateSelected: string = "";
  DriverState: string;
  dlState: string = "";
  dob: string = "";
  dobSelected: string;
  http: HttpClient;
  baseUrl: string;
  private DataChanged: boolean = false;

  constructor(private router: Router, private uiDataService: UIDataService, private UAS: UserAccountService, private spinner: NgxSpinnerService, _http: HttpClient, @Inject('BASE_URL') _baseUrl: string, private route: ActivatedRoute,) {
    this.baseUrl = _baseUrl;
    this.http = _http;
    
    this.CountriesObservable = this.http.get<Country[]>(this.baseUrl + 'api/Data/GetCanadaProviceCodes').pipe(share());

    this.spinner.hide();
    uiDataService.GetErrorMessage.subscribe(message => this.ErrorMessage = message);
    uiDataService.GetDriverInfoCanada.subscribe(message => this.driverInformation = message);

    // FIXED 5271 - Conviction Review Screen 
    if (this.IsMessageError(this.driverInformation.errorMessage)) {
      this.DisableButton(true);
    }
    else {
      this.DisableButton(false);
    }
    
    this.DriverState = this.driverInformation.driverState;

    // FIXED 5271 - Conviction Review Screen 
    if (this.driverInformation.errorMessage != "Error: No Data exist in the FCWD DB") {

      var dobSelected = this.driverInformation.generalInfo.driverDob.toString();
      this.DriverStateSelected = this.toDate(dobSelected.replace("T00:00:00", ""), "mm/dd/yyyy", "-");

      this.DOBForm.valueChanges.subscribe(g => {
        this.DOBFormControl.setValue(g.toLocaleDateString("en-US"), { emitEvent: false, onlySelf: true });
      });
      this.DOBFormControl.valueChanges.subscribe(g => {
        if (this.DOBFormControl.valid) {
          this.DOBForm.patchValue(new Date(g), { emitEvent: false, onlySelf: true });
        }
        else {
          this.DOBForm.patchValue('', { emitEvent: false, onlySelf: true });
        }
      });

      if (this.driverInformation.generalInfo.driverFirstName == "UNDEFINED") {
        this.driverInformation.generalInfo.driverFirstName = "";
      }
    }   
  }

  public IsMessageError(errorMesage: string) {
    if (errorMesage == "Date Of Birth Does Not Match Provincial Record; Conviction/Withdrawal cannot be added." ||
          errorMesage == "First Name Does Not Match Provincial Record:Last Name Does Not Match Provincial Record; Conviction/Withdrawal cannot be added." ||
            errorMesage == "First Name Does Not Match Provincial Record:Last Name Does Not Match Provincial Record:Date Of Birth Does Not Match Provincial Record; Conviction/Withdrawal cannot be added." ||
              errorMesage == "Error: No Data exist in the FCWD DB") {
      return true;
    }
    else {
      return false;
    }  
  }
  public DisableButton(disabled: boolean) {
    this.isDisabled = disabled;
  }

  public toDate(_date, _format, _delimiter) {
    var formatLowerCase = _format.toLowerCase();
    var formatItems = formatLowerCase.split(_delimiter);
    var dateItems = _date.split(_delimiter);
    var monthIndex = formatItems.indexOf("mm");
    var dayIndex = formatItems.indexOf("dd");
    var yearIndex = formatItems.indexOf("yyyy");
    var month = parseInt(dateItems[monthIndex]);
    month -= 1;
    var formatedDate = dateItems[1] + "/" + dateItems[2] + "/" + dateItems[0];
    return formatedDate;
  }

  public CreateConviction() {
    this.spinner.show();
   
    var myurl = `${"/canAddDriverInfo"}/${"canAddConviction"}`;
    this.router.navigateByUrl(myurl).then(e => {
      if (e) {
        this.spinner.hide();
      } else {
        this.spinner.hide();
      }
    });
  }

  public CreateWithdrawal() {
    this.spinner.show();
    var myurl = `${"/canAddDriverInfo"}/${"canAddWithdrawal"}`;
    
    this.router.navigateByUrl(myurl).then(e => {
      if (e) {
        this.spinner.hide();
      } else {
        this.spinner.hide();
      }
    });
  }

  public SubmitDriverQry() {
    if (this.provinceTypeform.valid && this.dlNo.valid && this.LastName.valid && this.DOBFormControl.valid) {
      /*format dbo*/
      var dobSelected = this.DOBFormControl.value;
      var re = /\//gi;
      this.dob = dobSelected.replace(re, '-');

      var firstName = this.FirstName.value;
      var myurl = "";

      this.dlState = this.provinceTypeform.value;

      /*goto canada detail page.ts*/
      if (firstName == "") {
        firstName = "undefined";
        myurl = `${"/canadaDetail"}/${this.dlState}/${this.dlNo.value}/${this.LastName.value}/${firstName}/${this.dob}`;
      }
      else {
        myurl = `${"/canadaDetail"}/${this.dlState}/${this.dlNo.value}/${this.LastName.value}/${this.FirstName.value}/${this.dob}`;
      }

      this.router.navigateByUrl(myurl);
    }
    else {
      this.provinceTypeform.markAsTouched();
      this.dlNo.markAsTouched();
      this.LastName.markAsTouched();
      /*this.FirstName.markAsTouched();*/
      this.DOBFormControl.markAsTouched();
    }
  }

  dlNo = new FormControl('', { updateOn: 'blur', validators: Validators.required });
  provinceform = new FormControl('', [Validators.required]);
  provinceTypeform = new FormControl('', [Validators.required]);
  LastName = new FormControl('', [Validators.required]);
  FirstName = new FormControl('', [Validators.required]);
  DOBFormControl = new FormControl({ value: '', disabled: false }, [Validators.required, dateErrorValidator]);
  DOBForm = new FormControl('', []);
  Country = new FormControl({ value: '', disabled: false });
}
