import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { UserAccountService } from '../account/userAccount.service';
import { AuthenticationService } from '../_services/authentication.service';
import { UIDataService } from '../driverDetail/uiData.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';
import { Guid } from 'guid-typescript';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit {
  public env = new env();
  public user: string;
  public driverInfoDataSource: string;
  public showNav: boolean;
  public currentYear: string = new Date().getFullYear().toString();
  currentApplicationVersion = environment.appVersion;

  //LOGIN.GOV
  public stateGuid: string;
  public nonceGuid: string;
  public acrValueUrl: string;
  public logOutResponseUrl: string;
  public clientUrl: string;
  public redirectUrl: string;
  public acr_Values: string;
  public client_Id: string;
  public prompt: string;
  public response_type: string;
  public scope: string;
  public state: string;
  public nonce: string;

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private uiService: UIDataService, private userACService: UserAccountService, private authenticationService: AuthenticationService, private router: Router) {
    this.authenticationService.IsActiveUser.subscribe(g => {
      if (g) {
        this.authenticationService.currentUser.subscribe(message => { this.user = message.userID, this.env.envType = message.environment });
        this.showNav = g;
      }
      else {
        this.showNav = g;
      }
    });
    this.uiService.GetDataSource.subscribe(message => { this.driverInfoDataSource = message });
  }

  ngOnInit() {
    //GET LOGIN.GOV PARAMETERS 
    this.authenticationService.getLoginGovParms().subscribe(
      data => {
        if (data != null) {
            this.logOutResponseUrl = data.logOutResponseUrl,
            this.clientUrl = data.clientUrl,
            this.client_Id = data.clientId            
        }
      });

  }

  public logout() {
    if (this.logout.call) {
      this.authenticationService.logout();

      this.stateGuid = new GuidCreate().id.toString();
      var url = `${this.clientUrl}/openid_connect/logout?client_id=` + this.client_Id + "&post_logout_redirect_uri=" + this.logOutResponseUrl + "&state=" + this.stateGuid;

      document.location.href = url;      
    }   
  }
}

export class env {
  envType: string;
}

export class GuidCreate {
  public id: Guid;
  constructor() {
    this.id = Guid.create(); // ==> b77d409a-10cd-4a47-8e94-b0cd0ab50aa1
  }
}
