import { Component, Inject, ViewChild, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { state } from '@angular/animations';
import { MatAccordion, fadeInContent } from '@angular/material';
import { Location } from '@angular/common';
import { UIDataService } from '../uiData.service';
import { Observable, Subscription } from 'rxjs';
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { DriverDetailInformation, conviction, withdrawal, GeneralInfo, ResidenceInfo, LicenseDetail, MedicalDetail } from '../DriverDetailInformation';
import { ActivatedRoute, Router } from '@angular/router';
import { UserAccountService } from '../../account/userAccount.service';
import { NgxSpinnerService } from 'ngx-spinner';

function dateErrorValidator(control: AbstractControl): { [key: string]: boolean } | null {
  let validFormat = new RegExp("^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{4})*$");
  let date: string[] = control.value.toString().split("/", 3);
  if (!validFormat.test(control.value.toString())) {
    return { 'dateError': true };
  }
  else {
    switch (Number(date[0])) {
      case 1: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 2: {
        if (Number(date[1]) == 29) {
          if ((Number(date[2]) % 4 == 0 && Number(date[2]) % 100 != 0) || (Number(date[2]) % 400 == 0)) {
            return null;
          }
          else {
            return { 'dateError': true };
          }
        }
        else {
          if (Number(date[1]) >= 1 && Number(date[1]) <= 28) {
            return null;
          }
          else {
            return { 'dateError': true };
          }
        }
      }
      case 3: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 4: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 30) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 5: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 6: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 30) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 7: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 8: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 9: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 30) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 10: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 11: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 30) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 12: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
    }
  }
  return null;
}


@Component({
  selector: 'app-driverResponseRecord-component',
  templateUrl: './driverResponseRecord.component.html'
})

export class driverResponseRecordComponent {
  public ErrorMessage: string;
  public DriverInfo = new DriverDetailInformation();
  constructor(private router: Router, private uiDataService: UIDataService, private UAS: UserAccountService, private spinner: NgxSpinnerService) {
    this.spinner.hide();
    uiDataService.GetErrorMessage.subscribe(message => this.ErrorMessage = message);
    uiDataService.GetDriverInfo.subscribe(message => this.DriverInfo = message);
  }

  public CreateConviction() {
    this.spinner.show();
    var myurl = `${"/addDriverInfo"}/${"addConviction"}`;
    this.router.navigateByUrl(myurl).then(e => {
      if (e) {
        this.spinner.hide();
      } else {
        this.spinner.hide();
      }
    });
  }


  public CreateWithdrawal() {
    this.spinner.show();
    var myurl = `${"/addDriverInfo"}/${"addWithdrawal"}`;
    this.router.navigateByUrl(myurl).then(e => {
      if (e) {
        this.spinner.hide();
      } else {
        this.spinner.hide();
      }
    });
  }

}
