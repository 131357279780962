
export class DriverDetailInformation {
  public generalInfo: GeneralInfo;
  public residenceInfo: ResidenceInfo;
  public licenseDetails: LicenseDetail;
  public withdrawals: withdrawal[];
  public medicalCertification: MedicalDetail;
  public endorsements: string[];
  public convictions: conviction[];
  public licenseRestrictions: string[];
  public history: string;
  public convictionData: conviction;
  public withdrawalData: withdrawal;
  public driverState: string;
  public driverLicense: string;
  public action: string;
  public dataSource: string;
  public errorMessageFCWD: string;
  public isErrorMessageFCWD: boolean;
  public WithdrawalActionPending: string;
  public WithdrawalActionSetForStaging: boolean;
  public isError: boolean;
  public errorMessage: string;
  public stateName: string;
  public userID: string;
  public isCDL: boolean;
  public messageToUI: string;
}
export class GeneralInfo {
  public driverLastName: string;
  public driverFirstName: string;
  public driverMiddleName: string;
  public driverSuffix: string;
  public driverDob: Date;
  public driverSex: string;
  public driverHeight: string;
  public driverWeight: string;
  public driverEyeColor: string;
}
export class ResidenceInfo {
  public driverStreetAddress1: string;
  public driverStreetAddress2: string;
  public driverMailingCity: string;
  public driverMailingState: string;
  public driverMailingZipcode: string;
  public driverMailingCounty: string;
  public driverMailingCountry: string;
}
export class withdrawal {
  public driverState: string;
  public driverLicenseNumber: string;
  public withdrawalEffectiveDate: string;
  public withdrawalEligibilityDate: string;
  public withdrawalEligibilityCode: string;
  public withdrawalJurisdictionCode: string;
  public withdrawalId: string;
  public linkage: string;
  public withdrawalAcdCode: string;
  public withdrawalActionTypeCode: string;
  public manualElectronic: string;
  public withdrawalBasisCode: string;
  public withdrawalExtentCode: string;
  public withdrawalJurisdictionLocator: string;
  public withdrawalReasonReference: string;
  public withdrawalDueProcStatCode: string;
  public withdrawalUserId: string;
  public withdrawalNoticePeriod: string;
  public withdrawalDuration: string;
  public withdrawalReinstateDate: string;
  public withdrawalReinstateLetterNeeded: string;
  public withdrawalReinstateSentDate: string;
  public withdrawalDisqLetterNeeded: string;
  public withdrawalDisqLetterSentDate: string;
  public withdrawalCategory: string;
  public withdrawalDateReceived: string;
  public withdrawalDatePosted: string;
  public withdrawalDqLetterMailedDate: string;
  public withdrawalLocatorNumber: string;
  public withdrawalStateNativeCode: string;
  public withdrawalHistoryComments: string;
}
export class LicenseDetail {
  public drivingLicenseId: string;
  public licenseState: string;
  public expDate: string;
  public issueDate: string;
  public commercialClass: string;
  public commercialStatus: string;
  public withdrawalActionPending: string;
}
export class conviction {
  constructor() {
    this.convCheck = false;
  }
  public driverState: string;
  public driverLicenseNumber: string;
  public convictionJurisdictionCode: string;
  public convictionCitationDate: string;
  public convictionDate: string;
  public convictionCourtType: string;
  public convictionJurisOffenseReference: string;
  public convictionOffenseLocator: string;
  public convictionCourtLocator: string;
  public convictionAcdCode: string;
  public convictionId: string;
  public convictionCategory: string;
  public convictionAcdCodeDetail: string;
  public convictionDriverCdlHolder: string;
  public convictionCommercialVehicle: string;
  public convictionHazmatVehicle: string;
  public convictionUserId: string;
  public convictionDateReceived: Date;
  public convictionDatePosted: Date;
  public convictionNotes: string;
  public convCheck: boolean;
}
export class MedicalDetail {
  public issueDate: string;
  public expDate: string;
  public statusCode: string;
  public selfCertification: string;
}
