import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FormControl, Validators } from '@angular/forms';
import { Router } from "@angular/router";
import { UIDataService } from '../driverDetail/uiData.service';
import { LoginModel, AuthenticationService } from '../_services/authentication.service';
import { User } from '../account/user';
import { SessionService } from '../_services/session.service';
import { Observable } from 'rxjs';

interface Country {
  jurisdictionCode: string;
  jurisdictionDesc: string;
  type: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})


export class HomeComponent implements OnInit {
  public countries: CountryDTO[];
  public driverCountry: CountryDTO[];
  public TypeOfCountry: CountryType[] = [{ value: "MX", Text: "Mexico" }, { value: "US-T", Text: "United States Territories" }, { value: "OTH", Text: "Other Countries" }];
  selected: string = "MX";
  countryType: string;
  Country: string;
  _http: HttpClient;
  _baseUrl: string;
  userInfo: Observable<User>;

  constructor(http: HttpClient, private router: Router, private idleTimeoutSvc: SessionService, private authService: AuthenticationService, @Inject('BASE_URL') baseUrl: string, private DataService: UIDataService) {
    this._http = http;
    this._baseUrl = baseUrl;
    http.get<CountryDTO[]>(baseUrl + 'api/Data/GetCountryCodes').subscribe(result => {
      this.countries = result;
    }, error => console.error(error));

    this.dlNo.valueChanges.subscribe(g => {
      this.dlNo.setValue(g.toString().toUpperCase(), { emitEvent: false, onlySelf: true });
    });   
  }

  ngOnInit() {
   this.authService.currentUser.subscribe(g => {
      if (g != null) {
        let Account = new LoginModel();
        Account.Password = "xxxxxxxxx";
        Account.UserID = g.userID;
        this.userInfo = this._http.post<User>(this._baseUrl + 'login/Account/RefreshToken', Account);
        this.userInfo.subscribe(g => {
          localStorage.removeItem('currentUser');
          localStorage.setItem('currentUser', JSON.stringify(g));
          this.idleTimeoutSvc.resetTimer();
        });
      }
    });
  }

  public FilterCountries() {
      this.driverCountry = this.countries.filter((item) => {
        return item.type === this.countryType
      });
      if (this.driverCountry.length > 0) {
        this.Country = this.driverCountry[0].jurisdictionCode;
      }            
      else {
        this.countryform.reset();
      }
  }

  dlNo = new FormControl('', { updateOn: 'blur', validators: Validators.required });
  countryform = new FormControl('', [Validators.required]);
  countryTypeform = new FormControl('', [Validators.required]);

  public DefaultValue() {
    if (this.driverCountry.length > 0) {
      return this.driverCountry[0].jurisdictionCode;
    }
    else {
      return "";
    }
  }
  public driverDetails() {
    
    if (this.dlNo.valid && this.countryTypeform.valid && this.countryform.valid) {
      var myurl = `${"/detail"}/${this.Country}/${this.dlNo.value}`;

      this.router.navigateByUrl(myurl);
    }
    else {
      this.dlNo.markAsTouched();
      this.countryTypeform.markAsTouched();
      this.countryform.markAsTouched();
    }
  };
}


export class CountryDTO {
  jurisdictionCode: string;
  jurisdictionDesc: string;
  type: string;
}

export class CountryType {
  value: string;
  Text: string;
}
