import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../account/user';
import { UserAccountService } from '../account/userAccount.service';
import { access } from 'fs';
import { stringify } from 'querystring';
import { LoginGov } from '../account/logingov';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  private IsActive = new BehaviorSubject<boolean>(false);
  private baseUrl: string;
  private environment: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') _baseUrl: string, private userAccountService: UserAccountService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.baseUrl = _baseUrl;
    this.environment = "";
    this.setActive(false);
  }

  public get currentUserValue(): User {
    this.currentUserSubject.next(null);
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    return this.currentUserSubject.value;
  }

  setActive(message: boolean) {
    this.IsActive.next(message);
  }
  IsActiveUser = this.IsActive.asObservable();

  getClientUrl() {
    this.http.get<string>(this.baseUrl + 'login/Account/getClientUrl').subscribe(g => {
      this.environment = g.toString();
   });

    return this.environment;
  }

  getLoginGovParms() {
    return this.http.get<LoginGov>(this.baseUrl + 'login/Account/GetLoginGovParms').pipe(map(logingov => {
      return logingov;
    }));
  }

  processToken(username: string) {
    var Account = new LoginModel();
    Account.UserID = username;
    return this.http.post<User>(this.baseUrl + 'login/Account/ProcessToken',Account).pipe(map(user => {
      if (user && user.token) {
        this.userAccountService.setUser(user.userID);
        this.userAccountService.setEnvironment(user.environment);
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        this.setActive(true);        
      }
      return user;
    }));
  }

  login(username: string, password: string) {
    var Account = new LoginModel();
    Account.UserID = username;
    Account.Password = password;
    return this.http.post<User>(this.baseUrl + 'login/Account/Login', Account).pipe(map(user => {
      if (user && user.token) {
        this.userAccountService.setUser(user.userID);
        this.userAccountService.setEnvironment(user.environment);
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        this.setActive(true);
      }
      return user;
    }));
  }

  refreshlogin() {
    var Account = new LoginModel();
  //  Account.UserID = this.currentUserValue.userID;
    //Account.Password = password;
    return this.http.post<User>(this.baseUrl + 'login/Account/Login', Account).pipe(map(user => {
      if (user && user.token) {
        this.userAccountService.setUser(user.userID);
        this.userAccountService.setEnvironment(user.environment);
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        this.setActive(true);
      }
      return user;
    }));
  }


  logout() {    
    // remove user from local storage to log user out
    this.IsActive.next(false);
    this.userAccountService.clearInfo();
    localStorage.removeItem('currentUser');

    this.currentUserSubject.next(null);
  }
}

export class LoginModel {
  UserID: string;
  Password: string;
}
