import { Injectable, Output, EventEmitter, Inject } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { UserAgreementModel } from './userAgreementModel';
import { Http } from '@angular/http';
import { HttpClient, HttpParams } from '@angular/common/http';
@Injectable()
export class UserAccountService {
  _http: HttpClient;
  _baseUrl: string;
  unsubscribe: Subject<any> = new Subject<any>();
  constructor(
    @Inject('BASE_URL') baseUrl: string,
    http: HttpClient

  ) {
    this._http = http;
    this._baseUrl = baseUrl;
  }
  private agreement = new BehaviorSubject<UserAgreementModel>(new UserAgreementModel());
  private user = new BehaviorSubject<string>("");
  private InfoMessages = new BehaviorSubject<Array<MessagesGroups>>([]);

  private environment = new BehaviorSubject<string>("");
  currentUser = this.user.asObservable();
  connectedEnvironment = this.environment.asObservable();
  infoMsg = this.InfoMessages.asObservable();
  getInfo() {
    this._http.get<MessagesGroups[]>(this._baseUrl + 'api/Info/GetAnnouncements').pipe(takeUntil(this.unsubscribe)).subscribe(result => {
      this.setInfo(result);
    }, error => console.error(error));

  }
  setInfo(msg: MessagesGroups[]) {
    this.InfoMessages.next(msg);
  }
  setEnvironment(message: string) {
    this.environment.next(message);
  }
  setUser(message: string) {
    this.user.next(message);
  }
  clearInfo() {
    this.user.next(null);
  }
  currentMessage = this.agreement.asObservable();
  sendMessage(message: UserAgreementModel) {
    this.agreement.next(message);
  }
}
export interface MessagesGroups {
  am_message: string;
}
