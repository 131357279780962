import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Observable, Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from '../../_services/authentication.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
interface convictonReport {
  state: string;
  drivingLicense: string;
  convictionDate: string;
  convictionDatePosted: string;
  transmissionMethod: string;
  days: string;

}

@Component({
  selector: 'app-convictionReport-component',
  templateUrl: './convictionReport.component.html',
  styleUrls: ['./convictionReport.component.css']

})


export class convictionReportComponent {

  public convictonReportObservable: Observable<convictonReport[]>;
  displayedConvictionData: string[];

  constructor(_http: HttpClient, @Inject('BASE_URL') _baseUrl: string, authService: AuthenticationService, public dialog: MatDialog, private spinner: NgxSpinnerService) {
    this.baseUrl = _baseUrl;
    this.http = _http;
    authService.currentUser.subscribe(g => { this.state = g.state });
    this.getConvictions();
  }
  state: string;
  startDate: Date;
  endDate: Date;
  http: HttpClient;
  baseUrl: string;
  public getConvictions() {
    this.spinner.show();
    this.displayedConvictionData = ['state', 'drivingLicense', 'convictionDate', 'convictionDatePosted', 'transmissionMethod', 'days']
    let Params = new HttpParams();
    Params = Params.append("state", this.state);
    this.convictonReportObservable = this.http.get<convictonReport[]>(this.baseUrl + 'api/Data/ConvictionTimelinessReports', { params: Params });
    this.spinner.hide();
  }


}
