import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';
import { AppComponent, sessionDialogComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { FAQComponent } from './faq/faq.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { ConvictionComponent } from './conviction/conviction.component';
import { CanadaComponent } from './canada/canada.component';
import { driverDetailComponent } from './driverDetail/driverDetail.component';
import { ConReviewComponent, MessageDialogComponent } from './convictionReview/conReview.component';
import { WithdrawalComponent } from './withdrawal/withdrawal.component'; 
import { driverStateComponent } from './driverState/driverState.component';
import { addConvictionComponent, PopupDialogComponent } from './driverDetail/addConviction/addConviction.component';

import { addWithdrawalComponent } from './driverDetail/addWithdrawal/addWithdrawal.component';
import { updateWithdrawalComponent } from './driverDetail/addWithdrawal/updateWithdrawal.component';
import { driverResponseRecordComponent } from './driverDetail/driverRecordResponse/driverResponseRecord.component';
import { addDriverInfoComponent } from './driverDetail/driverRecordResponse/addDriverInfo.component';

import { canadaDriverDetailComponent } from './canadaDriverDetail/canadaDriverDetail.component';
import { canAddWithdrawalComponent } from './canadaDriverDetail/canAddWithdrawal/canAddWithdrawal.component';
import { canUpdateWithdrawalComponent } from './canadaDriverDetail/canAddWithdrawal/canUpdateWithdrawal.component';
import { canAddConvictionComponent, PopupDialogComponentCanada } from './canadaDriverDetail/canAddConviction/canAddConviction.component';
import { canDriverResponseRecordComponent } from './canadaDriverDetail/canDriverRecordResponse/canDriverResponseRecord.component';
import { canAddDriverInfoComponent } from './canadaDriverDetail/canDriverRecordResponse/canAddDriverInfo.component';
import { determineCountryComponent } from './determineCountry/determineCountry.component';

import { convictionReportComponent } from './reports/convictionTimelinessReport/convictionReport.component';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { AppRoutingModule } from './app-routing.module';
import { loginComponent } from './account/login.component';
import { signInComponent } from './account/signin.component';
import { UIDataService } from './driverDetail/uiData.service';
import { UserAccountService } from './account/userAccount.service';
import { UserAgreementComponent } from './account/userAgreement.component';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import {
  MatAutocompleteModule,
  MatBadgeModule,
  MatBottomSheetModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatTreeModule,
  DateAdapter
} from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SessionService } from './_services/session.service';


@NgModule({
  exports: [
    CdkTableModule,
    CdkTreeModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
  ]
})
export class DemoMaterialModule { }

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    FAQComponent,
    CounterComponent,
    FetchDataComponent,
    ConvictionComponent,
    CanadaComponent,
    canadaDriverDetailComponent,  
    driverDetailComponent,
    ConReviewComponent,
    WithdrawalComponent,
    driverStateComponent,
    addConvictionComponent,
    canAddConvictionComponent,
    determineCountryComponent,
    driverResponseRecordComponent,
    canDriverResponseRecordComponent,
    addWithdrawalComponent,
    canAddWithdrawalComponent,
    updateWithdrawalComponent,
    canUpdateWithdrawalComponent,
    loginComponent,
    signInComponent,
    UserAgreementComponent,
    PopupDialogComponent,
    PopupDialogComponentCanada,
    MessageDialogComponent,
    addDriverInfoComponent,
    canAddDriverInfoComponent,
    convictionReportComponent,
    sessionDialogComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    HttpModule,
    HttpClientModule,
    FormsModule,
    DemoMaterialModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    AppRoutingModule,
    MatFormFieldModule,
    MatInputModule, NgxSpinnerModule, MatDatepickerModule],
  entryComponents: [ConReviewComponent, MessageDialogComponent, addConvictionComponent, PopupDialogComponent, canAddConvictionComponent, PopupDialogComponentCanada, sessionDialogComponent],
  providers: [UIDataService, SessionService, UserAccountService, { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule {
   
}
