export class ACDDTO {
  public acdCode: string;
  public convictionValidation: string;
  public moreInfoDescription: string;
  public description: string;
  public alternateCategory: string;
  public category: string;
  public conditionForAlternate: string;
  public conditionValue: string;
  public withdrawalValidation: string;
  public isError: boolean;
  public faultMessage: string;
}
