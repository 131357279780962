import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import "rxjs/Rx";
import { UIElementDTO } from '../uiElementDTO';
import { ACDDTO } from '../acdDTO';
import { CourtTypeDTO } from '../courtTypeDTO';
import { CommVehicleDTO } from '../commVehicleDTO';
import { HazmatDTO } from '../hazmatDTO';
import { StateDTO } from '../stateDTO';
import { UIDataService } from '../uiData.service';
import { FormControl, FormGroupDirective, NgForm, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { TooltipPosition } from '@angular/material';
import { DriverDetailInformation, conviction, withdrawal, GeneralInfo, ResidenceInfo, LicenseDetail, MedicalDetail } from '../DriverDetailInformation';
import { AddWithdrawalModel } from '../addWithdrawalModel';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from '../../_services/authentication.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

function dateErrorValidator(control: AbstractControl): { [key: string]: boolean } | null {
  let validFormat = new RegExp("^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{4})*$");
  let date: string[] = control.value.toString().split("/", 3);
  if (!validFormat.test(control.value.toString())) {
    return { 'dateError': true };
  }
  else {
    switch (Number(date[0])) {
      case 1: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 2: {
        if (Number(date[1]) == 29) {
          if ((Number(date[2]) % 4 == 0 && Number(date[2]) % 100 != 0) || (Number(date[2]) % 400 == 0)) {
            return null;
          }
          else {
            return { 'dateError': true };
          }
        }
        else {
          if (Number(date[1]) >= 1 && Number(date[1]) <= 28) {
            return null;
          }
          else {
            return { 'dateError': true };
          }
        }
      }
      case 3: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 4: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 30) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 5: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 6: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 30) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 7: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 8: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 9: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 30) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 10: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 11: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 30) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 12: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
    }
  }
  return null;
}

function reinstateDateValidator(control: AbstractControl): { [key: string]: boolean } | null {
  let validFormat = new RegExp("^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{4})*$");
  if (validFormat.test(control.value.toString())) {
    if (new Date(control.value) > new Date()) {
      return { 'reinstateDate': true };
    }
  }
  return null;
}
function eligDateValidator(DateForm: FormControl): ValidatorFn {
  let validFormat = new RegExp("^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{4})*$");
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (DateForm.valid) {
      if (validFormat.test(control.value.toString())) {
        if (new Date(control.value) > new Date(DateForm.value)) {
          return { 'eligDate': true };
        }
      }
    }
    return null;
  }
}
@Component({
  selector: 'app-updateWithdrawal-component',
  templateUrl: './addWithdrawal.component.html',
  styleUrls: ['./addWithdrawal.component.css']
})


export class updateWithdrawalComponent {
  datePattern = "^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{4})*$";
  displayedConvictionData: string[];
  public DriverWithdrawal = new DriverDetailInformation();
  CourtTypeObservable: Observable<CourtTypeDTO>;
  CommVehicleObservable: Observable<CommVehicleDTO>;
  ACDObservable: Observable<ACDDTO>;
  StateObservable: Observable<StateDTO>;
  UIElementObservable: Observable<WebUIElementDTO>;
  message: string;
  subscription: Subscription;
  baseUrl: string;
  maxStartDate = new Date();
  withdrawalDate: Date;
  noConvictions: boolean;
  actionCode: string;
  public withdrawalData = new withdrawal();
  public UpdateWithdrawalData = new withdrawalDTO();
  public IsUpdate: boolean;
  public DefaultValue = {};
  constructor(private spinner: NgxSpinnerService, private uiService: UIDataService, private authService: AuthenticationService, private http: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _location: Location) {
    this.IsUpdate = true;
    this.displayedConvictionData = ['Select', 'convictionCitationDate', 'convictionDate', 'convictionId', 'convictionJurisdictionCode', 'convictionCourtType', 'convictionAcdCode', 'convictionAcdCodeDetail', 'convictionDriverCdlHolder', 'convictionCommercialVehicle', 'convictionHazmatVehicle', 'convictionJurisOffenseReference', 'driverState', 'convictionUserId', 'convictionCategory', 'convictionDateReceived', 'convictionDatePosted'];
    this.baseUrl = _baseUrl;
    let Params = new HttpParams();
    this.authService.currentUser.subscribe(g => {
      Params = Params.append("state", g.state);
      this.UIElementObservable = this.http.get<WebUIElementDTO>(this.baseUrl + 'api/Data/UIElementsVMForWithdrawals', { params: Params });
    });
    this.uiService.GetWithdrawal.subscribe(message => { this.DriverWithdrawal = message, this.DriverWithdrawal.convictions = [], this.PublishWithdrawalData(message.withdrawalData) });
    this.uiService.GetWithdrawal.subscribe(message => (message.convictions.length <= 0) ? this.noConvictions = false : this.noConvictions = true);
    this.WithdrawalEffectiveDateForm.valueChanges.subscribe(g => {
      this.WithdrawalEffectiveDateFormControl.setValue(g.toLocaleDateString("en-US"), { emitEvent: false, onlySelf: true });
    });
    this.WithdrawalEffectiveDateFormControl.valueChanges.subscribe(g => {
      if (this.WithdrawalEffectiveDateFormControl.valid) {
        this.WithdrawalEffectiveDateForm.patchValue(new Date(g), { emitEvent: false, onlySelf: true });
      }
      else {
        this.WithdrawalEffectiveDateForm.patchValue('', { emitEvent: false, onlySelf: true });
      }
    });
    this.WithdrawalEligibleDateForm.valueChanges.subscribe(g => {
      this.WithdrawalEligibleDateFormControl.setValue(g.toLocaleDateString("en-US"), { emitEvent: false, onlySelf: true });
      if (this.withdrawalEligibleDateList.value == "INDEF" || this.withdrawalEligibleDateList.value == "PERM") {
        this.WithdrawalEligibleDateFormControl.setValidators([Validators.required, dateErrorValidator]);
        this.withdrawalEligibleDateList.patchValue('', { emitEvent: false, onlySelf: true });
      }
      this.WithdrawalEligibleDateFormControl.updateValueAndValidity({ emitEvent: false, onlySelf: true });
      this.WithdrawalEligibleDateFormControl.markAsTouched();
    });
    this.WithdrawalEligibleDateFormControl.valueChanges.subscribe(g => {
      if (this.WithdrawalEligibleDateFormControl.valid) {
        this.WithdrawalEligibleDateForm.patchValue(new Date(g), { emitEvent: false, onlySelf: true });
      }
      else {
        this.WithdrawalEligibleDateForm.patchValue('', { emitEvent: false, onlySelf: true });
      }
      if (this.withdrawalEligibleDateList.value == "INDEF" || this.withdrawalEligibleDateList.value == "PERM") {
        this.WithdrawalEligibleDateFormControl.setValidators([Validators.required, dateErrorValidator]);
        this.WithdrawalEligibleDateFormControl.updateValueAndValidity({ emitEvent: false, onlySelf: true });
        this.withdrawalEligibleDateList.patchValue('', { emitEvent: false, onlySelf: true });
      }
    });

    this.WithdrawalReinstatedDateForm.valueChanges.subscribe(g => {
      this.WithdrawalReinstatedDateFormControl.setValue(g.toLocaleDateString("en-US"), { emitEvent: false, onlySelf: true });
      this.WithdrawalReinstatedDateFormControl.markAsTouched();
      this.WithdrawalEligibleDateFormControl.updateValueAndValidity({ emitEvent: false, onlySelf: true });
    });
    this.WithdrawalReinstatedDateFormControl.valueChanges.subscribe(g => {
      if (!this.WithdrawalReinstatedDateFormControl.hasError('dateError')) {
        this.WithdrawalReinstatedDateForm.patchValue(new Date(g), { emitEvent: false, onlySelf: true });
      }
      else {
        this.WithdrawalReinstatedDateForm.patchValue('', { emitEvent: false, onlySelf: true });
      }
      this.WithdrawalEligibleDateFormControl.updateValueAndValidity({ emitEvent: false, onlySelf: true });
    });

    this.withdrawalEligibleDateList.valueChanges.subscribe(g => {
      this.WithdrawalEligibleDateForm.patchValue('', { emitEvent: false, onlySelf: true });
      this.WithdrawalEligibleDateFormControl.patchValue('', { emitEvent: false, onlySelf: true });
      if (g == "INDEF" || g == "PERM") {
        this.WithdrawalEligibleDateFormControl.setValidators(null);
        this.WithdrawalEligibleDateFormControl.updateValueAndValidity({ emitEvent: false, onlySelf: true });
      }
      else {
        this.WithdrawalEligibleDateFormControl.setValidators([Validators.required, dateErrorValidator]);
        this.WithdrawalEligibleDateFormControl.updateValueAndValidity({ emitEvent: false, onlySelf: true });
      }
    });
    window.scrollTo(0, 0);
  }
  public backClicked() {
    this._location.back();
  }


  public PublishWithdrawalData(data: withdrawal) {
    this.withdrawalData = data;
    this.UIElementObservable.subscribe(message => {
      message.withdrawalActionList.forEach(g => {
        if (g.desc == this.DriverWithdrawal.withdrawalData.withdrawalActionTypeCode) {
          this.WithdrawalActionFormControl = new FormControl({ value: g.value, disabled: true }, [
            Validators.required
          ]);

        }
      });
      message.withdrawalExtent.forEach(g => {
        if (g.desc == this.DriverWithdrawal.withdrawalData.withdrawalExtentCode) {
          this.WithdrawalExtentCodeFormControl = new FormControl({ value: g.value, disabled: false }, []);
        }
      });
      message.withdrawalBasisList.forEach(g => {
        if (g.desc == this.DriverWithdrawal.withdrawalData.withdrawalBasisCode) {
          this.WithdrawalBasisFormControl = new FormControl({ value: g.value, disabled: false }, []);
        }
      });
      message.withdrawalProcStatusList.forEach(g => {
        if (g.desc == this.DriverWithdrawal.withdrawalData.withdrawalDueProcStatCode) {
          this.WithdrawalDueProcStatusFormControl = new FormControl({ value: g.value, disabled: false }, []);

        }
      });
    });

    this.acdCodeFormControl.patchValue(this.DriverWithdrawal.withdrawalData.withdrawalAcdCode);// = new FormControl({ value: this.DriverWithdrawal.withdrawalData.withdrawalAcdCode, disabled: true }, []);
    this.acdCodeFormControl.disable();
    this.WithdrawalStateNativeCodeFormControl.patchValue(this.DriverWithdrawal.withdrawalData.withdrawalReasonReference);
    this.WithdrawalStateNativeCodeFormControl.disable();

    this.WithdrawalLocatorNumberFormControl.patchValue(this.DriverWithdrawal.withdrawalData.withdrawalJurisdictionLocator);// = new FormControl({ value: this.DriverWithdrawal.withdrawalData.withdrawalJurisdictionLocator, disabled: true }, [
    this.WithdrawalLocatorNumberFormControl.disable();
    this.WithdrawalEffectiveDateFormControl.patchValue(new Date(this.DriverWithdrawal.withdrawalData.withdrawalEffectiveDate).toLocaleDateString("en-US"));
    this.WithdrawalEffectiveDateFormControl.disable();

    if (data.withdrawalEligibilityDate == "INDEF" || data.withdrawalEligibilityDate == "PERM") {
      this.WithdrawalEligibleDateFormControl.patchValue("");

      this.withdrawalEligibleDateList.patchValue(this.DriverWithdrawal.withdrawalData.withdrawalEligibilityDate);// = new FormControl({ value: this.DriverWithdrawal.withdrawalData.withdrawalEligibilityDate, disabled: false }, [

    }
    else {
      this.WithdrawalEligibleDateFormControl.patchValue(new Date(this.DriverWithdrawal.withdrawalData.withdrawalEligibilityDate).toLocaleDateString("en-US"));
    }

    if (this.DriverWithdrawal.withdrawalData.withdrawalReinstateDate != "") {
      this.WithdrawalReinstatedDateFormControl.patchValue(new Date(this.DriverWithdrawal.withdrawalData.withdrawalReinstateDate).toLocaleDateString("en-US"));
    }
    this.LinkageFormControl = new FormControl({ value: this.DriverWithdrawal.withdrawalData.linkage, disabled: true }, []);
    this.LinkFormControl = new FormControl({ value: this.DriverWithdrawal.withdrawalData.withdrawalId, disabled: true }, []);

    this.WithdrawalComments = new FormControl({ value: ""/*this.DriverWithdrawal.withdrawalData.withdrawalHistoryComments*/, disabled: false }, [
      Validators.required
    ]);
    this.userIdFormControl = new FormControl({ value: this.DriverWithdrawal.userID, disabled: true }, []);
    this.WithdrawalPostedDateFormControl = new FormControl({ value: new Date(this.DriverWithdrawal.withdrawalData.withdrawalDatePosted), disabled: true }, []);
  }
  public UpdateWithDrawal() {
    if (this.WithdrawalComments.invalid || this.WithdrawalEffectiveDateFormControl.invalid || (this.WithdrawalEligibleDateFormControl.invalid || this.withdrawalEligibleDateList.value == "") || this.WithdrawalEffectiveDateFormControl.invalid || this.WithdrawalStateNativeCodeFormControl.invalid || this.WithdrawalLocatorNumberFormControl.invalid) {
      this.WithdrawalEffectiveDateFormControl.markAsTouched();
      this.WithdrawalComments.markAsTouched();
      this.WithdrawalEligibleDateFormControl.markAsTouched();
      this.WithdrawalStateNativeCodeFormControl.markAsTouched();
      this.WithdrawalLocatorNumberFormControl.markAsTouched();
      window.scrollTo(0, 0);
    }
    else {
      this.spinner.show();
      this.UpdateWithdrawalData = { withdrawalId: this.withdrawalData.withdrawalId, withdrawalUserId: this.DriverWithdrawal.userID, withdrawalAcdCode: this.acdCodeFormControl.value, withdrawalActionTypeCode: this.WithdrawalActionFormControl.value, withdrawalBasisCode: this.WithdrawalBasisFormControl.value, withdrawalCategory: this.withdrawalData.withdrawalCategory, withdrawalDatePosted: this.withdrawalData.withdrawalDatePosted, withdrawalDateReceived: this.withdrawalData.withdrawalDateReceived, withdrawalDisqLetterNeeded: null, withdrawalDisqLetterSentDate: null, driverLicenseNumber: this.DriverWithdrawal.driverLicense, driverState: this.DriverWithdrawal.driverState, withdrawalDqLetterMailedDate: null, withdrawalDueProcStatCode: this.WithdrawalDueProcStatusFormControl.value, withdrawalDuration: this.withdrawalEligibleDateList.value, withdrawalEffectiveDate: this.WithdrawalEffectiveDateFormControl.value, withdrawalEligibilityDate: this.WithdrawalEligibleDateFormControl.value, withdrawalExtentCode: this.WithdrawalExtentCodeFormControl.value, withdrawalHistoryComments: this.WithdrawalComments.value, withdrawalJurisdictionCode: null, withdrawalJurisdictionLocator: this.WithdrawalStateNativeCodeFormControl.value, withdrawalNoticePeriod: null, withdrawalReasonReference: this.WithdrawalLocatorNumberFormControl.value, withdrawalReinstateDate: this.WithdrawalReinstatedDateFormControl.value, withdrawalReinstLetterSentDate: null };
      this.uiService.GetLoginState.subscribe(message => this.UpdateWithdrawalData.withdrawalJurisdictionCode = message);
      this.authService.currentUser.subscribe(message => this.UpdateWithdrawalData.withdrawalUserId = message.userID);
      let response = this.RequestAPI(this.UpdateWithdrawalData);
      response.subscribe(
        data => {
          if (!data) {
            this.DriverWithdrawal.isError = true;
            this.DriverWithdrawal.errorMessage = "Undefined";
            window.scrollTo(0, 0);
            this.spinner.hide();

          }
          else {
            this.backClicked();
          }
        },
        error => {
          console.log("Error", error);
          window.scrollTo(0, 0);
          this.spinner.hide();

        }
      );
      //this.http.post<boolean>(this.baseUrl + 'api/Data/UpdateWithdrawal', this.UpdateWithdrawalData).subscribe(
      //  data => {
      //    if (!data) {
      //      this.DriverWithdrawal.isError = true;
      //      this.DriverWithdrawal.errorMessage = "Undefined";
      //      window.scrollTo(0, 0);
      //      this.spinner.hide();

      //    }
      //    else {
      //      this.backClicked();
      //    }
      //  },
      //  error => {
      //    console.log("Error", error);
      //    window.scrollTo(0, 0);
      //    this.spinner.hide();

      //  }
      //);
    }
  };
  private RequestAPI(requestdata) {
    return this.http.post(this.baseUrl + 'api/Data/UpdateWithdrawal', requestdata).share();
  };
  WithdrawalStateNativeCodeFormControl = new FormControl('', [
    Validators.required
  ]);
  WithdrawalLocatorNumberFormControl = new FormControl('', [
    Validators.required
  ]);
  WithdrawalExtentCodeFormControl = new FormControl('', [
    Validators.required
  ]);
  WithdrawalDueProcStatusFormControl = new FormControl('', [
    Validators.required
  ]);
  WithdrawalBasisFormControl = new FormControl('', [
    Validators.required
  ]);
  WithdrawalActionFormControl = new FormControl('', [
    Validators.required
  ]);
  offRefFormControl = new FormControl('', [
    Validators.required
  ]);

  WithdrawalReinstatedDateForm = new FormControl('', []);
  withdrawalEligibleDateList = new FormControl('', [
  ]);
  acdCodeFormControl = new FormControl({ value: '', disabled: true }, [
    Validators.required
  ]);
  jurisdictionFormControl = new FormControl('', [
    Validators.required
  ]);
  WithdrawalEffectiveDateFormControl = new FormControl('', [
    Validators.required, dateErrorValidator
  ]);

  WithdrawalEffectiveDateForm = new FormControl({ value: '', disabled: true }, [
  ]);
  WithdrawalEligibleDateForm = new FormControl('', [
  ]);
  WithdrawalPostedDateFormControl = new FormControl({ value: new Date(), disabled: true }, []);
  WithdrawalComments = new FormControl('Withdrawal Added', [
    Validators.required
  ]);
  WithdrawalReinstatedDateFormControl = new FormControl('', [
    dateErrorValidator, reinstateDateValidator
  ]);
  WithdrawalEligibleDateFormControl = new FormControl('', [
    Validators.required, dateErrorValidator, eligDateValidator(this.WithdrawalReinstatedDateFormControl)
  ]);
  userIdFormControl = new FormControl('', []);
  LinkageFormControl = new FormControl({ value: '', disabled: true }, []);
  LinkFormControl = new FormControl({ value: '', disabled: true }, []);

}


export class WebUIElement {
  public value: string;
  public desc: string;
}

export class WebUIElementDTO {
  public withdrawalActionList: WebUIElement[];
  public withdrawalProcStatusList: WebUIElement[];
  public withdrawalEligibleDateList: WebUIElement[];
  public withdrawalBasisList: WebUIElement[];
  public acdCodes: ACDDTO[];
  public withdrawalDate: Date;
  public withdrawalExtent: WebUIElement[];
}
export class withdrawalDTO {
  driverState: string;
  driverLicenseNumber: string;
  withdrawalJurisdictionCode: string;
  withdrawalJurisdictionLocator: string;
  withdrawalEffectiveDate: string;
  withdrawalActionTypeCode: string;
  withdrawalAcdCode: string;
  withdrawalReasonReference: string;
  withdrawalId: string;
  withdrawalEligibilityDate: string;
  withdrawalReinstateDate: string;
  withdrawalExtentCode: string;
  withdrawalCategory: string;
  withdrawalNoticePeriod: string;
  withdrawalDuration: string;
  withdrawalReinstLetterSentDate: string;
  withdrawalDisqLetterNeeded: string;
  withdrawalDisqLetterSentDate: string;
  withdrawalUserId: string;
  withdrawalDateReceived: string;
  withdrawalDatePosted: string;
  withdrawalDqLetterMailedDate: string;
  withdrawalDueProcStatCode: string;
  withdrawalBasisCode: string;
  withdrawalHistoryComments: string;
}

