import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

interface State {
  jurisdictionCode: string;
  jurisdictionDesc: string;
}

@Component({
  selector: 'app-driverState-component',
  templateUrl: './driverState.component.html'
})

export class driverStateComponent {
  public driverstates: State[]
  selected = "MX";
  DriverState: string;
  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    http.get<State[]>(baseUrl + 'api/Data/GetJurisdictionCodes').subscribe(result => {
      this.driverstates = result;
    }, error => console.error(error));
  }
}
