export class AddConvictionModel {
  convictionOffenceReference: string;
  convictionOffenceLocator: string;
  convictionReceived: Date;
  convictionPosted: Date;
  linkID: string;
  userID: string;
  historyComments: string;
  driverState: string;
  driverLicense: string;
  citationDate: string;
  convictionDate: Date;
  acdCode: string;
  acdDetail: string;
  courtType: string;
  hazmat: string;
  commercialVehicle: string;
  convictionJurisdiction: string;
  driverLastName: string;
  driverFirstName: string;
  driverMiddleName: string;
  driverSuffix: string;
  driverDob: Date;
  driverSex: string;
  driverHeight: string;
  driverWeight: string;
  driverEyeColor: string;
  convictionIsNew: boolean;
  convictionIsForeignCallNeeded: boolean;
  convictionManualElectronicIndicator: string;
}
