import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { map, tap } from "rxjs/operators";
import { UIElementDTO } from '../../driverDetail/uiElementDTO';
import { ACDDTO } from '../../driverDetail/acdDTO';
import { CourtTypeDTO } from '../../driverDetail/courtTypeDTO';
import { CommVehicleDTO } from '../../driverDetail/commVehicleDTO';
import { HazmatDTO } from '../../driverDetail/hazmatDTO';
import { StateDTO } from '../../driverDetail/stateDTO';
import { UIDataService } from '../../driverDetail/uiData.service';
import { FormControl, FormGroupDirective, NgForm, Validators, AbstractControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { TooltipPosition } from '@angular/material';
import { AuthenticationService, LoginModel } from '../../_services/authentication.service';
import { DriverDetailInformation, conviction, withdrawal, GeneralInfo, ResidenceInfo, LicenseDetail, MedicalDetail } from '../../driverDetail/DriverDetailInformation';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { User } from '../../account/user';
import { SessionService } from '../../_services/session.service';


export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

function dateErrorValidator(control: AbstractControl): { [key: string]: boolean } | null {
  let validFormat = new RegExp("^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{4})*$");
  let date: string[] = control.value.toString().split("/", 3);
  if (!validFormat.test(control.value.toString())) {
    return { 'dateError': true };
  }
  else {
    switch (Number(date[0])) {
      case 1: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 2: {
        if (Number(date[1]) == 29) {
          if ((Number(date[2]) % 4 == 0 && Number(date[2]) % 100 != 0) || (Number(date[2]) % 400 == 0)) {
            return null;
          }
          else {
            return { 'dateError': true };
          }
        }
        else {
          if (Number(date[1]) >= 1 && Number(date[1]) <= 28) {
            return null;
          }
          else {
            return { 'dateError': true };
          }
        }
      }
      case 3: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 4: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 30) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 5: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 6: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 30) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 7: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 8: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 9: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 30) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 10: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 11: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 30) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 12: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
    }
  }
  return null;
}
function reinstateDateValidator(control: AbstractControl): { [key: string]: boolean } | null {
  let validFormat = new RegExp("^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{4})*$");
  if (validFormat.test(control.value.toString())) {
    if (new Date(control.value) > new Date()) {
      return { 'reinstateDate': true };
    }
  }
  return null;
}

@Component({
  selector: 'app-canAddWithdrawal-component',
  templateUrl: './canAddWithdrawal.component.html',
  styleUrls: ['./canAddWithdrawal.component.css']
})


export class canAddWithdrawalComponent {
  displayedConvictionData: string[];
  public driverDobSelected: string;
  public DriverWithdrawal = new DriverDetailInformation();
  CourtTypeObservable: Observable<CourtTypeDTO>;
  CommVehicleObservable: Observable<CommVehicleDTO>;
  ACDObservable: Observable<ACDDTO>;
  StateObservable: Observable<StateDTO>;
  UIElementObservable: Observable<WebUIElementDTO>;
  message: string;
  subscription: Subscription;
  baseUrl: string;
  withdrawalDate: Date;
  public IsUpdate: boolean;
  noConvictions: boolean;
  maxStartDate = new Date();
  userInfo: Observable<User>;

  public DefaultValue = {
    Action: '6', Basis: '1', DueProc: '2', ACD: 'A04', Extent: '1'
  };
  datePattern = "^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{4})*$";

  constructor(private spinner: NgxSpinnerService, private idleTimeoutSvc: SessionService, private uiService: UIDataService, private authService: AuthenticationService, private http: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _location: Location) {   
    this.IsUpdate = false;
    this.displayedConvictionData = ['Select', 'driverState', 'driverLicenseNumber', 'convictionCitationDate', 'convictionDate', 'convictionId', 'convictionJurisdictionCode', 'convictionCourtType', 'convictionAcdCode', 'convictionAcdCodeDetail', 'convictionDriverCdlHolder', 'convictionCommercialVehicle', 'convictionHazmatVehicle', 'convictionOffenseLocator', 'convictionJurisOffenseReference', 'convictionCategory', 'convictionUserId', 'convictionDateReceived', 'convictionDatePosted'];
    this.baseUrl = _baseUrl;
    let Params = new HttpParams();
    this.authService.currentUser.subscribe(g => {
      Params = Params.append("state", g.state);
      this.UIElementObservable = this.http.get<WebUIElementDTO>(this.baseUrl + 'api/Data/UIElementsVMForWithdrawals', { params: Params });
    });
    this.uiService.GetWithdrawal.subscribe(message => this.DriverWithdrawal = message);
    this.uiService.GetWithdrawal.subscribe(message => (message.convictions.length <= 0) ? this.noConvictions = false : this.ShowConviction(message.convictions[0].convictionId));
    this.uiService.GetWithdrawal.subscribe(message => { this.userIdFormControl = new FormControl({ value: message.userID, disabled: true }, []); });
    this.WithdrawalEffectiveDateForm.valueChanges.subscribe(g => {
      this.WithdrawalEffectiveDateFormControl.setValue(g.toLocaleDateString("en-US"), { emitEvent: false, onlySelf: true });
    });
    this.WithdrawalEffectiveDateFormControl.valueChanges.subscribe(g => {
      if (this.WithdrawalEffectiveDateFormControl.valid) {
        this.WithdrawalEffectiveDateForm.patchValue(new Date(g), { emitEvent: false, onlySelf: true });
      }
      else {
        this.WithdrawalEffectiveDateForm.patchValue('', { emitEvent: false, onlySelf: true });
      }
    });
    this.WithdrawalEligibleDateForm.valueChanges.subscribe(g => {
      this.WithdrawalEligibleDateFormControl.setValue(g.toLocaleDateString("en-US"), { emitEvent: false, onlySelf: true });
      if (this.withdrawalEligibleDateList.value == "INDEF" || this.withdrawalEligibleDateList.value == "PERM") {
        this.WithdrawalEligibleDateFormControl.setValidators([Validators.required, dateErrorValidator]);
        this.withdrawalEligibleDateList.patchValue('', { emitEvent: false, onlySelf: true });
      }
      this.WithdrawalEligibleDateFormControl.updateValueAndValidity({ emitEvent: false, onlySelf: true });
      this.WithdrawalEligibleDateFormControl.markAsTouched();
    });
    this.WithdrawalEligibleDateFormControl.valueChanges.subscribe(g => {
      if (this.WithdrawalEligibleDateFormControl.valid) {
        this.WithdrawalEligibleDateForm.patchValue(new Date(g), { emitEvent: false, onlySelf: true });
      }
      else {
        this.WithdrawalEligibleDateForm.patchValue('', { emitEvent: false, onlySelf: true });
      }
      if (this.withdrawalEligibleDateList.value == "INDEF" || this.withdrawalEligibleDateList.value == "PERM") {
        this.WithdrawalEligibleDateFormControl.setValidators([Validators.required, dateErrorValidator]);
        this.WithdrawalEligibleDateFormControl.updateValueAndValidity({ emitEvent: false, onlySelf: true });
        this.withdrawalEligibleDateList.patchValue('', { emitEvent: false, onlySelf: true });
      }
    });

    this.WithdrawalReinstatedDateForm.valueChanges.subscribe(g => {
      this.WithdrawalReinstatedDateFormControl.setValue(g.toLocaleDateString("en-US"), { emitEvent: false, onlySelf: true });
      this.WithdrawalReinstatedDateFormControl.markAsTouched();
      this.WithdrawalEligibleDateFormControl.updateValueAndValidity({ emitEvent: false, onlySelf: true });
    });
    this.WithdrawalReinstatedDateFormControl.valueChanges.subscribe(g => {
      if (!this.WithdrawalReinstatedDateFormControl.hasError('dateError')) {
        this.WithdrawalReinstatedDateForm.patchValue(new Date(g), { emitEvent: false, onlySelf: true });
      }
      else {
        this.WithdrawalReinstatedDateForm.patchValue('', { emitEvent: false, onlySelf: true });
      }
      this.WithdrawalEligibleDateFormControl.updateValueAndValidity({ emitEvent: false, onlySelf: true });
    });

    this.withdrawalEligibleDateList.valueChanges.subscribe(g => {
      this.WithdrawalEligibleDateForm.patchValue('', { emitEvent: false, onlySelf: true });
      this.WithdrawalEligibleDateFormControl.patchValue('', { emitEvent: false, onlySelf: true });
      if (g == "INDEF" || g == "PERM") {
        this.WithdrawalEligibleDateFormControl.setValidators(null);
        this.WithdrawalEligibleDateFormControl.updateValueAndValidity({ emitEvent: false, onlySelf: true });
      }
      else {
        this.WithdrawalEligibleDateFormControl.setValidators([Validators.required, dateErrorValidator]);
        this.WithdrawalEligibleDateFormControl.updateValueAndValidity({ emitEvent: false, onlySelf: true });
      }
    });

    /*format dob TODO: */
    var dobSelected = this.DriverWithdrawal.generalInfo.driverDob.toString();
    var re = /\//gi;
    this.driverDobSelected = dobSelected.replace(re, '-');

    window.scrollTo(0, 0);
  }
  private ShowConviction(convictionId: string) {
    this.noConvictions = true;
    this.LinkFormControl = new FormControl({ value: convictionId, disabled: true }, []);
  }

  public AddWithDrawal() {
    this.authService.currentUser.subscribe(g => {
      if (g != null) {
        var Account = new LoginModel();
        Account.Password = "xxxxxxxxx";
        Account.UserID = g.userID;
        this.userInfo = this.http.post<User>(this.baseUrl + 'login/Account/RefreshToken', Account);
        this.userInfo.subscribe(g => {
          localStorage.removeItem('currentUser');
          localStorage.setItem('currentUser', JSON.stringify(g));
          this.idleTimeoutSvc.resetTimer();
        });
      }
    });
    if (this.acdCodeFormControl.invalid || this.WithdrawalComments.invalid || this.WithdrawalEffectiveDateFormControl.invalid || (this.WithdrawalEligibleDateFormControl.invalid && this.withdrawalEligibleDateList.value == "") || this.WithdrawalEffectiveDateFormControl.invalid || this.WithdrawalStateNativeCodeFormControl.invalid || this.WithdrawalLocatorNumberFormControl.invalid) {
      this.WithdrawalEffectiveDateFormControl.markAsTouched();
      this.WithdrawalComments.markAsTouched();
      this.WithdrawalEligibleDateFormControl.markAsTouched();
      this.WithdrawalStateNativeCodeFormControl.markAsTouched();
      this.WithdrawalLocatorNumberFormControl.markAsTouched();
      this.acdCodeFormControl.markAsTouched();
      window.scrollTo(0, 0);
      /*CAN I DISPLAY MESSAGE TO SCREEN - TODO:*/
    }
    else {
      //#BUG 5595 FIXED check and see if HQ account is updating on PRODUCTION  OR TEST     
      var userState = this.authService.currentUserValue.state;
    
      if (userState.toUpperCase() == "HQ") {
        this.DriverWithdrawal.errorMessage = "HQ users are unable to create convictions, withdrawals or negates.";
        this.DriverWithdrawal.isError = true;
        window.scrollTo(0, 0);
        this.spinner.hide();
      }
      else {
        this.spinner.show();
        let EligiblityCode = "";
        if (this.withdrawalEligibleDateList.value != null || this.withdrawalEligibleDateList.value != "") {
          EligiblityCode = this.withdrawalEligibleDateList.value;
        }
        this.DriverWithdrawal.withdrawalData = { driverLicenseNumber: this.DriverWithdrawal.driverLicense, withdrawalEligibilityCode: EligiblityCode, driverState: this.DriverWithdrawal.driverState, withdrawalHistoryComments: this.WithdrawalComments.value, withdrawalDisqLetterSentDate: null, withdrawalDqLetterMailedDate: null, withdrawalReinstateSentDate: null, linkage: null, manualElectronic: null, withdrawalDuration: null, withdrawalDateReceived: null, withdrawalReinstateLetterNeeded: null, withdrawalJurisdictionLocator: this.WithdrawalLocatorNumberFormControl.value, withdrawalDisqLetterNeeded: null, withdrawalJurisdictionCode: null, withdrawalCategory: null, withdrawalUserId: null, withdrawalId: null, withdrawalNoticePeriod: null, withdrawalReasonReference: this.WithdrawalStateNativeCodeFormControl.value, withdrawalAcdCode: this.acdCodeFormControl.value, withdrawalEffectiveDate: this.WithdrawalEffectiveDateFormControl.value, withdrawalEligibilityDate: this.WithdrawalEligibleDateFormControl.value, withdrawalReinstateDate: this.WithdrawalReinstatedDateFormControl.value, withdrawalActionTypeCode: this.WithdrawalActionFormControl.value, withdrawalBasisCode: this.WithdrawalBasisFormControl.value, withdrawalDueProcStatCode: this.WithdrawalDueProcStatusFormControl.value, withdrawalExtentCode: this.WithdrawalExtentCodeFormControl.value, withdrawalLocatorNumber: this.WithdrawalLocatorNumberFormControl.value, withdrawalStateNativeCode: this.WithdrawalStateNativeCodeFormControl.value, withdrawalDatePosted: this.WithdrawalPostedDateFormControl.value };
        this.uiService.GetLoginState.subscribe(message => this.DriverWithdrawal.withdrawalData.withdrawalJurisdictionCode = message);
        if (this.DriverWithdrawal.convictions.length > 0) {
          this.DriverWithdrawal.convictions.forEach(g => {
            if (g.convCheck == false) {
              const index: number = this.DriverWithdrawal.convictions.indexOf(g);
              this.DriverWithdrawal.convictions.splice(index, 1);
            }
          });
        }

        /*no change*/
        this.http.post<DriverDetailInformation>(this.baseUrl + 'api/Data/AddWithdrawalCanada', this.DriverWithdrawal).subscribe(
          data => {
            if (data.isError) {
              this.DriverWithdrawal = data;
              window.scrollTo(0, 0);
            }
            else {
              this.backClicked();
            }
            this.spinner.hide();
          },
          error => {
            console.log("Error", error);
            window.scrollTo(0, 0);
            this.spinner.hide();

          }
        );
      }     
    }
  };
  public UpdateWithDrawal() {
    return null;
  }

  public backClicked() {
    this._location.back();
  }

  WithdrawalStateNativeCodeFormControl = new FormControl('', [
    Validators.required
  ]);
  WithdrawalLocatorNumberFormControl = new FormControl('', [
    Validators.required
  ]);
  WithdrawalExtentCodeFormControl = new FormControl(this.DefaultValue.Extent, [
    Validators.required
  ]);
  WithdrawalDueProcStatusFormControl = new FormControl(this.DefaultValue.DueProc, [
    Validators.required
  ]);
  WithdrawalBasisFormControl = new FormControl(this.DefaultValue.Basis, [
    Validators.required
  ]);
  WithdrawalActionFormControl = new FormControl(this.DefaultValue.Action, [
    Validators.required
  ]);
  offRefFormControl = new FormControl('', [
    Validators.required
  ]);
  WithdrawalReinstatedDateFormControl = new FormControl('', [
    dateErrorValidator, reinstateDateValidator
  ]);
  WithdrawalReinstatedDateForm = new FormControl('', []);
  withdrawalEligibleDateList = new FormControl('', [
  ]);
  acdCodeFormControl = new FormControl(this.DefaultValue.ACD, [
    Validators.required
  ]);
  jurisdictionFormControl = new FormControl('', [
    Validators.required
  ]);
  WithdrawalEffectiveDateFormControl = new FormControl('', [
    Validators.required, dateErrorValidator
  ]);
  WithdrawalEligibleDateFormControl = new FormControl('', [
    Validators.required, dateErrorValidator
  ]);
  WithdrawalEffectiveDateForm = new FormControl('', [
  ]);
  WithdrawalEligibleDateForm = new FormControl('', [
  ]);
  WithdrawalPostedDateFormControl = new FormControl({ value: new Date(), disabled: true }, []);
  WithdrawalComments = new FormControl('Withdrawal Added', [
    Validators.required
  ]);
  userIdFormControl = new FormControl('', []);
  LinkageFormControl = new FormControl({ value: '', disabled: true }, []);
  LinkFormControl = new FormControl({ value: '', disabled: true }, []);
}


export class WebUIElement {
  public value: string;
  public desc: string;
}

export class WebUIElementDTO {
  public withdrawalActionList: WebUIElement[];
  public withdrawalProcStatusList: WebUIElement[];
  public withdrawalEligibleDateList: WebUIElement[];
  public withdrawalBasisList: WebUIElement[];
  public acdCodes: ACDDTO[];
  public withdrawalDate: Date;
  public withdrawalExtent: WebUIElement[];
}
