import { Injectable, Output, EventEmitter, Inject  } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { AddConvictionModel } from './addConvitionModel';
import { AddWithdrawalModel } from './addWithdrawalModel';
import { DriverDetailInformation, conviction, withdrawal, GeneralInfo, ResidenceInfo, LicenseDetail, MedicalDetail } from './DriverDetailInformation';



@Injectable()
export class UIDataService {
  private Conviction = new BehaviorSubject<DriverDetailInformation>(new DriverDetailInformation());
  private Withdrawal = new BehaviorSubject<DriverDetailInformation>(new DriverDetailInformation());
  private DriverInformation = new BehaviorSubject<DriverDetailInformation>(new DriverDetailInformation());
  private DataSource = new BehaviorSubject<string>("");
  private LoginState = new BehaviorSubject<string>("");
  private ErrorMessage = new BehaviorSubject<string>("");
  GetDataSource = this.DataSource.asObservable();
  SendDataSource(message: string) {
    this.DataSource.next(message);
  };
  GetConviction = this.Conviction.asObservable();
  SendConviction(message: DriverDetailInformation) {
    this.Conviction.next(message);
  }
  GetWithdrawal = this.Withdrawal.asObservable();
  sendWithdrawal(message: DriverDetailInformation) {
    this.Withdrawal.next(message);
  }
  GetErrorMessage = this.ErrorMessage.asObservable();
  SendErrorMessage(message: string) {
    this.ErrorMessage.next(message);
  }
  GetDriverInfo = this.DriverInformation.asObservable();
  SendDriverInfo(message: DriverDetailInformation) {
    this.DriverInformation.next(message);
  }
  GetDriverInfoCanada = this.DriverInformation.asObservable();
  SendDriverInfoCanada(message: DriverDetailInformation) {
    this.DriverInformation.next(message);
  }
  GetLoginState = this.LoginState.asObservable();
  SendLoginState(message: string) {
    this.LoginState.next(message);
  }
}
