import { Component, Inject, Injectable, OnInit } from '@angular/core';
import { AuthenticationService, LoginModel } from './_services/authentication.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';
import { windowCount, window, first } from 'rxjs/operators';
import { Observable, Subject, Subscription, timer } from 'rxjs';
import { UserAccountService } from './account/userAccount.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UIDataService } from './driverDetail/uiData.service';
import { User } from './account/user';
import { SessionService } from './_services/session.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { Guid } from 'guid-typescript';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})

export class AppComponent implements OnInit {
  
  ngOnInit(): void {
    //MODIFY CR6133 NO ACTIVITY FOR 15MINS
    this._idleTimerSubscription = this.idleTimeoutSvc.timeoutExpired.subscribe(res => {
      this.authenticationService.currentUser.subscribe(g => {
        if (g != null) {
          this.authenticationService.logout();

          //LOGIN.GOV
          var url: string;
          var stateGuid: string;

          this.authenticationService.getLoginGovParms().subscribe(
            data => {
              if (data != null) {
                stateGuid = new GuidCreate().id.toString();
                url = `${data.clientUrl}/openid_connect/logout?client_id=` + data.clientId + "&post_logout_redirect_uri=" + data.logOutResponseUrl + "&state=" + stateGuid;

                document.location.href = url;
              }
            });         
          }
        });
    });
  }
  ngOnDestroy() {
    this._idleTimerSubscription.unsubscribe();
  }
  public _counter: number = 0;
  public _status: string = "Initialized.";
  private _timer: Observable<number>;
  private _timerSubscription: Subscription;
  private _idleTimerSubscription: Subscription;
  http: HttpClient;
  baseUrl: string;
  userInfo: Observable<User>;
  private refreshTokenCalled: boolean = false;
  private CounterStarted: boolean = false;
  public PopUpDialog: SessionDialog;

  constructor(private router: Router, public dialog: MatDialog, private idleTimeoutSvc: SessionService, _http: HttpClient, @Inject('BASE_URL') _baseUrl: string, private authenticationService: AuthenticationService, private uiService: UIDataService, private spinner: NgxSpinnerService, private authService: AuthenticationService) {
    this.http = _http;
    this.baseUrl = _baseUrl;

    //this.GetRefreshToken();
    const el = document.getElementById('my-element');

    // Create an Observable that will publish mouse movements
    const mouseMoves = fromEvent(el, 'mousemove');

    // Subscribe to start listening for mouse-move events
    const subscription = mouseMoves.subscribe((evt: MouseEvent) => {
      // Log coords of mouse movements
      if (this._counter == 0) {
        authenticationService.currentUser.subscribe(g => {
          if (g != null && !this.CounterStarted) {
            this.startCounter();
          }
        });
      }
      else if (this._counter > 1700 && !this.refreshTokenCalled) {
        this.CounterStarted = false;
        authenticationService.currentUser.subscribe(g => {
          if (g != null) {

            this.refreshTokenCalled = true;
            var Account = new LoginModel();
            Account.Password = "xxxxxxxxx";
            Account.UserID = g.userID;
            this.userInfo = this.http.post<User>(this.baseUrl + 'login/Account/RefreshToken', Account);
            this.userInfo.subscribe(g => {
              this.reset();
              this.CounterStarted = false;
              localStorage.removeItem('currentUser');
              localStorage.setItem('currentUser', JSON.stringify(g));
              this.refreshTokenCalled = false;
            });


          }
        });
      }
      else {
      }
      // When the mouse is over the upper-left of the screen,
      // unsubscribe to stop listening for mouse movements
      if (evt.clientX < 40 && evt.clientY < 40) {
        subscription.unsubscribe();
      }

    });

  }

  public startCounter() {
    this.CounterStarted = true;
    if (this._timerSubscription) {
      this._timerSubscription.unsubscribe();
    }

    this._counter = 0;
    this._timer = timer(1000, 1000);
    this._timerSubscription = this._timer.subscribe(n => {
      this._counter++;
    });
  }

  public reset() {
    this.startCounter();
    this._status = "Initialized.";
    this.idleTimeoutSvc.resetTimer();
  }
}


@Component({
  selector: 'app-sessionDialog-component',
  templateUrl: './convictionReview/messageDialog.component.html',
})

export class sessionDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<sessionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SessionDialog) {
    dialogRef.disableClose = true;
  }
}


interface SessionDialog {
  header: string;
  message: string;
  buttonOk: boolean;
  buttonClose: boolean;
  action: boolean;
  buttonOkLabel: string;
  buttonCloseLabel: string;
}

export class GuidCreate {
  public id: Guid;
  constructor() {
    this.id = Guid.create(); // ==> b77d409a-10cd-4a47-8e94-b0cd0ab50aa1
  }
}
