import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { map, tap } from "rxjs/operators";
import { UIElementDTO } from '../../driverDetail/uiElementDTO';
import { ACDDTO } from '../../driverDetail/acdDTO';
import { CourtTypeDTO } from '../../driverDetail/courtTypeDTO';
import { CommVehicleDTO } from '../../driverDetail/commVehicleDTO';
import { HazmatDTO } from '../../driverDetail/hazmatDTO';
import { StateDTO } from '../../driverDetail/stateDTO';
import { UIDataService } from '../../driverDetail/uiData.service';
import { FormControl, FormGroupDirective, NgForm, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { TooltipPosition } from '@angular/material';
import { AddConvictionModel } from '../../driverDetail/addConvitionModel';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DriverDetailInformation, conviction, withdrawal, GeneralInfo, ResidenceInfo, LicenseDetail, MedicalDetail } from '../../driverDetail/DriverDetailInformation'; 
import { Location } from '@angular/common';
import { AuthenticationService, LoginModel } from '../../_services/authentication.service';
import { Tree } from '@angular/router/src/utils/tree';
import { SessionService } from '../../_services/session.service';
import { User } from '../../account/user';

function dateErrorValidator(control: AbstractControl): { [key: string]: boolean } | null {
  let validFormat = new RegExp("^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{4})*$");
  let date: string[] = control.value.toString().split("/", 3);
  if (!validFormat.test(control.value.toString())) {
    return { 'dateError': true };
  }
  else {
    switch (Number(date[0])) {
      case 1: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 2: {
        if (Number(date[1]) == 29) {
          if ((Number(date[2]) % 4 == 0 && Number(date[2]) % 100 != 0) || (Number(date[2]) % 400 == 0)) {
            return null;
          }
          else {
            return { 'dateError': true };
          }
        }
        else {
          if (Number(date[1]) >= 1 && Number(date[1]) <= 28) {
            return null;
          }
          else {
            return { 'dateError': true };
          }
        }
      }
      case 3: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 4: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 30) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 5: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 6: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 30) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 7: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 8: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 9: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 30) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 10: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 11: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 30) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 12: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
    }
  }
  return null;
}


function customFormValidator(dateForm: FormControl): ValidatorFn {

  return (control: AbstractControl): { [key: string]: boolean } | null => {

    if (dateForm.valid) {
      if (new Date(dateForm.value) >= new Date("2008-01-01T00:00:00") && (control.value == 9 || control.value == "9")) {
        return { 'customForm': true };
      }
    }
    return null;

  };

}

function cmvHazmatValidator(DateForm: FormControl, HazMatForm: FormControl): ValidatorFn {

  return (control: AbstractControl): { [key: string]: boolean } | null => {

    if (HazMatForm.valid && DateForm.valid) {
      if ((control.value == 1 || control.value == "1" || control.value == 2 || control.value == "2") && control.value != HazMatForm.value && new Date(DateForm.value) < new Date("2008-01-01T00:00:00")) {
        return { 'cmvHazmat': true };
      }
      if (control.value != HazMatForm.value && new Date(DateForm.value) >= new Date("2008-01-01T00:00:00")) {
        return { 'cmvHazmat': true };
      }
    }
    if (HazMatForm.valid && DateForm.invalid) {
      if (control.value != HazMatForm.value) {
        return { 'cmvHazmat': true };
      }
    }
    return null;

  };

}



export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-canAddConviction-component',
  templateUrl: './canAddConviction.component.html',
  styleUrls: ['./canAddConviction.component.css']
})


export class canAddConvictionComponent {
  CMVIndicatorValidator = new ConvictionValidation();
  HazmatValidator = new ConvictionValidation();
  maxStartDate = new Date();
  public ACDDetailMessage = new ACDDetailDesc();
  public ACDHint = new ACDDTO();
  public ACDHints: AcdCodehint[];
  CourtTypeObservable: Observable<CourtTypeDTO>;
  CommVehicleObservable: Observable<CommVehicleDTO>;
  ACDObservable: Observable<ACDDTO>;
  public acdDetailErrorMessage: string;
  StateObservable: Observable<StateDTO>;
  public ConvictionModel = new AddConvictionModel();
  public DriverConviction = new DriverDetailInformation();
  public driverDobSelected: string;
  UIElementObservable: Observable<UIElementDTO>;
  message: string;
  subscription: Subscription;
  baseUrl: string;
  acdDetailRequired: string[];
  acdCodesForCMVHazmat: string[];
  matcher = new MyErrorStateMatcher();
  acdDetailInfo: string;
  userID: string;
  datePattern = "^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{4})*$";
  userInfo: Observable<User>;

  constructor(public dialog: MatDialog, private idleTimeoutSvc: SessionService, private authService: AuthenticationService, private uiService: UIDataService, private http: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _location: Location) {
    this.baseUrl = _baseUrl;
    let Params = new HttpParams();

    /*no change*/
    this.authService.currentUser.subscribe(g => {
      Params = Params.append("state", g.state);
      this.UIElementObservable = this.http.get<UIElementDTO>(this.baseUrl + 'api/Data/UIElements', { params: Params });
    });
    this.uiService.GetConviction.subscribe(message => this.DriverConviction = message);
    this.uiService.GetConviction.subscribe(message => { this.userIdFormControl = new FormControl({ value: message.userID, disabled: true }, []); });
    this.acdCodesForCMVHazmat = ["B19", "B27", "B56", "B57", "E03", "E04", "E53", "U09", "U10"];
    this.acdCodeFormControl.valueChanges.subscribe(g => {
      this.UIElementObservable.subscribe(data => {
        this.ACDHint = data.acdCodes.find(x => x.acdCode == g);
      });
    });

    this.citationDateForm.valueChanges.subscribe(g => {
      this.citationDateFormControl.setValue(g.toLocaleDateString("en-US"), { emitEvent: false, onlySelf: true });
      this.hazmatFormControl.updateValueAndValidity();
      this.commvehicleFormControl.updateValueAndValidity();
    });
    this.citationDateFormControl.valueChanges.subscribe(g => {
      if (this.citationDateFormControl.valid) {
        this.citationDateForm.patchValue(new Date(g), { emitEvent: false, onlySelf: true });
        this.hazmatFormControl.updateValueAndValidity();
        this.commvehicleFormControl.updateValueAndValidity();
      }
      else {
        this.citationDateForm.patchValue('', { emitEvent: false, onlySelf: true });
      }
    });
    this.convictionDateForm.valueChanges.subscribe(g => {
      this.convictionDateFormControl.setValue(g.toLocaleDateString("en-US"), { emitEvent: false, onlySelf: true });
    });
    this.convictionDateFormControl.valueChanges.subscribe(g => {
      if (this.convictionDateFormControl.valid) {
        this.convictionDateForm.patchValue(new Date(g), { emitEvent: false, onlySelf: true });
      }
      else {
        this.convictionDateForm.patchValue('', { emitEvent: false, onlySelf: true });
      }
    });

    /*format dob TODO: */
    var dobSelected = this.DriverConviction.generalInfo.driverDob.toString();
    var re = /\//gi;
    this.driverDobSelected = dobSelected.replace(re, '-');

    window.scrollTo(0, 0);
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(PopupDialogComponentCanada, {
      width: '480px',
      data: this.ACDHint
    });
    dialogRef.afterClosed().subscribe(result => {
      this.acdDetailInfo = "";
    });
  }
  private SearchAcdCodeDetails(data: string): boolean {
    let result: boolean;
    for (let i in this.ACDHints) {
      if (this.ACDHints[i].acdCode == data) {
        if (this.ACDHints[i].conditionValue == "Required") {
          result = true;
        }
        else {
          result = false;
        }
        break;
      }
    }
    return result;
  };
  public acdDetailIsRequired(acdCode: string) {
    switch (acdCode) {
      case "A11": {
        this.acdDetailFormControl = new FormControl('', [Validators.required]);
        break;
      }
      case "A91": {
        this.acdDetailFormControl = new FormControl('', [Validators.required]);
        break;
      }
      case "D45": {
        this.acdDetailFormControl = new FormControl('', [Validators.required]);
        break;
      }
      case "D53": {
        this.acdDetailFormControl = new FormControl('', [Validators.required]);
        break;
      }
      case "D56": {
        this.acdDetailFormControl = new FormControl('', [Validators.required]);
        break;
      }
      case "S92": {
        this.acdDetailFormControl = new FormControl('', [Validators.required]);
        break;
      }
      default: {
        this.acdDetailFormControl = new FormControl('', []);
        this.ACDDetailMessage.IsRequired = false;
        this.ACDDetailMessage.IsOptional = true;
      }
    }
  }

  public MoreInfo() {
    this.openDialog();
  }

  public SaveConviction() {
    this.authService.currentUser.subscribe(g => {
      if (g != null) {
        var Account = new LoginModel();
        Account.Password = "xxxxxxxxx";
        Account.UserID = g.userID;
        this.userInfo = this.http.post<User>(this.baseUrl + 'login/Account/RefreshToken', Account);
        this.userInfo.subscribe(g => {
          localStorage.removeItem('currentUser');
          localStorage.setItem('currentUser', JSON.stringify(g));
          this.idleTimeoutSvc.resetTimer();
        });
      }
    });

    if (this.acdCodeFormControl.invalid || this.convictionDateFormControl.invalid || this.citationDateFormControl.invalid || this.offLocatorFormControl.invalid || this.courtTypeFormControl.invalid || this.offRefFormControl.invalid || this.hazmatFormControl.invalid || this.commvehicleFormControl.invalid || this.acdDetailFormControl.invalid) {
      window.scrollTo(0, 0);
      this.convictionDateFormControl.markAsTouched();
      this.citationDateFormControl.markAsTouched();
      this.courtTypeFormControl.markAsTouched();
      this.commvehicleFormControl.markAsTouched();
      this.hazmatFormControl.markAsTouched();
      this.commentsFormControl.markAsTouched();
      this.acdCodeFormControl.markAsTouched();
      this.offLocatorFormControl.markAsTouched();
      this.offRefFormControl.markAsTouched();
      this.acdDetailFormControl.markAsTouched();
    }
    else
    {
     
      if (this.acdDetailFormControl.hasError('required')) {
          this.DriverConviction.isError = true;
          this.DriverConviction.errorMessage = this.ACDDetailError();
          window.scrollTo(0, 0);
      }
      //#TASK 5595 FIXED check and see if HQ account is updating on PRODUCTION       
      var userState = this.authService.currentUserValue.state;

      if (userState.toUpperCase() == "HQ") {
        this.DriverConviction.errorMessage = "HQ users are unable to create convictions, withdrawals or negates.";
        this.DriverConviction.isError = true;
        window.scrollTo(0, 0);
      }
      else {
          this.DriverConviction.isError = false;
          this.DriverConviction.licenseDetails.licenseState = this.DriverConviction.driverState;

          this.DriverConviction.convictions = [];
          this.DriverConviction.convictionData = { driverLicenseNumber: this.DriverConviction.driverLicense, driverState: this.DriverConviction.driverState, convictionAcdCode: this.acdCodeFormControl.value, convictionAcdCodeDetail: this.acdDetailFormControl.value, convictionCategory: null, convictionCitationDate: this.citationDateFormControl.value, convictionCommercialVehicle: this.commvehicleFormControl.value, convictionCourtType: this.courtTypeFormControl.value, convictionDate: this.convictionDateFormControl.value, convictionHazmatVehicle: this.hazmatFormControl.value, convictionDatePosted: new Date(this.convictionDatePostedFormControl.value), convictionDateReceived: this.convictionDatePostedFormControl.value, convictionDriverCdlHolder: (this.DriverConviction.isCDL) ? "yes" : "no", convictionJurisdictionCode: this.jurisdictionFormControl.value, convictionOffenseLocator: this.offLocatorFormControl.value, convictionJurisOffenseReference: this.offRefFormControl.value, convictionId: null, convictionUserId: null, convCheck: false, convictionCourtLocator: null, convictionNotes: this.commentsFormControl.value };
          this.uiService.GetLoginState.subscribe(message => { this.DriverConviction.convictionData.convictionJurisdictionCode = message; this.DriverConviction.convictionData.convictionCourtLocator = message; });

          /* no change */
          this.http.post<DriverDetailInformation>(this.baseUrl + 'api/Data/AddConvictionCanada', this.DriverConviction).subscribe(
            data => {
              if (data.isError) {
                window.scrollTo(0, 0);
                this.DriverConviction = data;
              }
              else {
                this.backClicked();
              }
            }
            ,
            error => {
              window.scrollTo(0, 0);
            }
          );
        }                 
    }
  }

  public backClicked() {
    this._location.back();
  }


  public HazmatValidation() {
    if (this.citationDateFormControl.valid && this.hazmatFormControl.valid) {
      if (new Date(this.citationDateFormControl.value) >= new Date("2008-01-01T00:00:00")) {
        this.hazmatFormControl.setValidators([customFormValidator, Validators.required]);
        this.hazmatFormControl.updateValueAndValidity({ emitEvent: false, onlySelf: true });
      }
      else {
        this.hazmatFormControl.clearValidators();
        this.hazmatFormControl.setValidators([Validators.required]);
        this.hazmatFormControl.updateValueAndValidity({ emitEvent: false, onlySelf: true });
      }
    }
  }
  public CMVValidation() {
    if (this.citationDateFormControl.valid && this.commvehicleFormControl.valid && this.hazmatFormControl.valid) {


      if (new Date(this.citationDateFormControl.value) >= new Date("2008-01-01T00:00:00")) {
        this.commvehicleFormControl.setValidators([customFormValidator, Validators.required]);
        this.commvehicleFormControl.updateValueAndValidity({ emitEvent: false, onlySelf: true });
      }
      if (this.commvehicleFormControl.value != this.hazmatFormControl.value) {
        this.commvehicleFormControl.updateValueAndValidity({ emitEvent: false, onlySelf: true });
      }
      else {
        this.commvehicleFormControl.clearValidators();
        this.commvehicleFormControl.setValidators([Validators.required]);
        this.commvehicleFormControl.updateValueAndValidity({ emitEvent: false, onlySelf: true });
      }
    }
    if (this.citationDateFormControl.valid && this.commvehicleFormControl.valid && this.hazmatFormControl.invalid) {
      if (new Date(this.citationDateFormControl.value) >= new Date("2008-01-01T00:00:00")) {
        this.commvehicleFormControl.setValidators([customFormValidator, Validators.required]);
        this.commvehicleFormControl.updateValueAndValidity({ emitEvent: false, onlySelf: true });
      }

      else {
        this.commvehicleFormControl.clearValidators();
        this.commvehicleFormControl.setValidators([Validators.required]);
        this.commvehicleFormControl.updateValueAndValidity({ emitEvent: false, onlySelf: true });
      }
    }
    if (this.citationDateFormControl.invalid && this.commvehicleFormControl.valid && this.hazmatFormControl.valid) {
      if (this.commvehicleFormControl.value != this.hazmatFormControl.value) {
        this.commvehicleFormControl.updateValueAndValidity({ emitEvent: false, onlySelf: true });
      }
      else {
        this.commvehicleFormControl.clearValidators();
        this.commvehicleFormControl.setValidators([Validators.required]);
        this.commvehicleFormControl.updateValueAndValidity({ emitEvent: false, onlySelf: true });
      }
    }
  }
  private ACDDetailError() {
    let ACDCode: string;
    ACDCode = this.acdCodeFormControl.value;
    switch (ACDCode) {
      case "S92": {
        return "ACD Code XXX requires the speed limit (L) and the speed (S) in the detail field: LLSSS.";
      }
      case "A11": {
        return "ACD Code XXX requires the Blood Alcohol Content in the detail field.";
      }
      case "A91": {
        return "ACD Code XXX requires the Blood Alcohol Content in the detail field.";
      }
      case "S45": {
        return "ACD Code XXX requires the ACD Code of the underlying citation in the detail field.";
      }
      case "S53": {
        return "ACD Code XXX requires the ACD Code of the underlying citation in the detail field.";
      }
      case "S56": {
        return "ACD Code XXX requires the ACD Code of the underlying citation in the detail field.";
      }
      default: {
        return "";
      }
    }
  }

  offRefFormControl = new FormControl('', [
    Validators.required
  ]);
  offLocatorFormControl = new FormControl('', [
    Validators.required
  ]);
  acdDetailFormControl = new FormControl('', []);

  acdCodeFormControl = new FormControl('', [
    Validators.required
  ]);
  jurisdictionFormControl = new FormControl('', [
    Validators.required
  ]);
  citationDateFormControl = new FormControl('', [
    Validators.required, dateErrorValidator
  ]);
  convictionDateFormControl = new FormControl('', [
    Validators.required, dateErrorValidator
  ]);
  citationDateForm = new FormControl('', [
  ]);
  convictionDateForm = new FormControl('', [
  ]);
  courtTypeFormControl = new FormControl('', [
    Validators.required
  ]);
  commentsFormControl = new FormControl('Conviction Added', [
    Validators.required, Validators.max(500)
  ]);
  hazmatFormControl = new FormControl('', [
    Validators.required, customFormValidator(this.citationDateFormControl)
  ]);
  commvehicleFormControl = new FormControl('', [
    Validators.required, customFormValidator(this.citationDateFormControl)

  ]);
  convictionDateReceivedFormControl = new FormControl(new Date(), [
    Validators.required
  ]);
  convictionDatePostedFormControl = new FormControl({ value: new Date(), disabled: true }, [
    Validators.required
  ]);
  userIdFormControl = new FormControl('', []);
  convictionOffRefFormControl = new FormControl('', []);
}

@Component({
  selector: 'app-PopupDialog-component',
  templateUrl: './canDialog.component.html',
})

export class PopupDialogComponentCanada {
  constructor(
    public dialogRef: MatDialogRef<PopupDialogComponentCanada>,
    @Inject(MAT_DIALOG_DATA) public data: AcdCodehint) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

export class ACDDetailDesc {
  public Required: string;
  public Detail: string;
  public Explanation: string;
  public IsRequired: boolean;
  public IsOptional: boolean;
}

export class AcdCodehint {
  public acdCode: string;
  public convictionValidation: string;
  public moreInfoDescription: string;
  public description: string;
  public alternateCategory: string;
  public category: string;
  public conditionForAlternate: string;
  public conditionValue: string;
  public withdrawalValidation: string;
  public isError: boolean;
  public faultMessage: string;
}
export class ConvictionValidation {
  public IsError: boolean;
  public Message: string;
  public MesssageIdx: number;
}

