import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HttpClient, HttpParams } from '@angular/common/http';
import { interceptingHandler } from '@angular/common/http/src/module';
import { Observable, Subscription, Subject } from 'rxjs';
import { DriverDetailInformation, conviction, withdrawal, GeneralInfo, ResidenceInfo, LicenseDetail, MedicalDetail } from '../driverDetail/DriverDetailInformation';
import { UIDataService } from '../driverDetail/uiData.service';
import { Router } from "@angular/router";
import { NgxSpinnerService } from 'ngx-spinner';import { AuthenticationService, LoginModel } from '.././_services/authentication.service';
import { User } from '../account/user';
import { SessionService } from '../_services/session.service';

interface ConvictionReviewTable {
  check: boolean;
  driverState: string;
  driverLicense: string;
  jurisdictionData: string;
  convictionID: string;
  withdrawalID: string;
  convictionWithdrawalCode: string;
  convictionReviewReason: string;
  foreignData: string;
  reviewReceiveDate: string;
  convictionReviewID: number;
}


@Component({
  selector: 'app-conReview-component',
  templateUrl: './conReview.component.html',
  styleUrls: ['./conReview.component.css']
})
export class ConReviewComponent {
  public PopUpDialog: Dialog;
  public RemoveConvictionReviewData = new RemoveConvictionReview();
  public ListConvictionReviewData: RemoveConvictionReview[] = [];
  public ListDictConvictionReview: DictConvictionReview[] = [];
  public DictConvictionReviewData = new DictConvictionReview();
  public dataSourceObservable: Observable<ConvictionReviewTable[]>;
  public dataSource: ConvictionReviewTable[];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  private baseUrl: string;
  public loginState: string;
  userInfo: Observable<User>;

  displayedColumns: string[] = ['Action', 'check', 'driverState', 'driverLicense', 'convictionID', 'withdrawalID', 'convictionReviewReason', 'reviewReceiveDate'];
  constructor(private spinner: NgxSpinnerService, private idleTimeoutSvc: SessionService, private http: HttpClient, @Inject('BASE_URL') _baseUrl: string, private router: Router, public dialog: MatDialog, uiservice: UIDataService, private authService: AuthenticationService) {
    this.authService.currentUser.subscribe(g => this.loginState = g.state);
    this.baseUrl = _baseUrl;
    let Params = new HttpParams();
    Params = Params.append("jurisdiction", this.loginState);
    this.dataSourceObservable = this.http.get<ConvictionReviewTable[]>(this.baseUrl + 'api/Data/ConvictionReviews', { params: Params });
  }



  public deleteConvictionReview(driverState, driverLicense, convictionReviewID) {
    this.authService.currentUser.subscribe(g => {
      if (g != null) {
        var Account = new LoginModel();
        Account.Password = "xxxxxxxxx";
        Account.UserID = g.userID;
        this.userInfo = this.http.post<User>(this.baseUrl + 'login/Account/RefreshToken', Account);
        this.userInfo.subscribe(g => {
          localStorage.removeItem('currentUser');
          localStorage.setItem('currentUser', JSON.stringify(g));
          this.idleTimeoutSvc.resetTimer();
        });
      }
    });
    this.RemoveConvictionReviewData.ConvictionReviewId = convictionReviewID;
    this.RemoveConvictionReviewData.DriverState = driverState;
    this.RemoveConvictionReviewData.DriverLicenseNumber = driverLicense;
    const dialogRef = this.dialog.open(MessageDialogComponent, { width: '400px', data: { header: 'Confirmation', message: 'Are you sure you want to delete the record(s) ?', buttonOk: true, buttonClose: true, action: true, buttonOkLabel: 'Continue', buttonCloseLabel: 'Cancel' } });
    dialogRef.afterClosed().subscribe(result => {
      if (result == true && result != undefined) {
        this.spinner.show();
        this.http.post<boolean>(this.baseUrl + 'api/Data/DeleteConvictionReview', this.RemoveConvictionReviewData).subscribe(
          data => {
            this.spinner.hide();
            if (data) {
              let Params = new HttpParams();
              Params = Params.append("jurisdiction", this.loginState);
              this.dataSourceObservable = this.http.get<ConvictionReviewTable[]>(this.baseUrl + 'api/Data/ConvictionReviews', { params: Params });
            }

          },
          error => {
            this.spinner.hide();

          }
        );
      }
    });

  }

  private serverResponseMessage(result: boolean) {
    if (result) {
      const dialogMessage = this.dialog.open(MessageDialogComponent, { width: '400px', data: { header: 'Success', message: 'Data deleted successfully', buttonOk: true, buttonClose: false, action: false, buttonOkLabel: 'Ok', buttonCloseLabel: 'Cancel' } });
      dialogMessage.afterClosed().subscribe(result => {
      });
    }
    else {
      const dialogMessage = this.dialog.open(MessageDialogComponent, { width: '400px', data: { header: 'Error', message: 'Unable to delete data', buttonOk: true, buttonClose: false, action: false, buttonOkLabel: 'Ok', buttonCloseLabel: 'Cancel' } });
      dialogMessage.afterClosed().subscribe(result => {
      });
    }

  }

  public PushConvictionReview(checked: boolean, driverState: string, driverLicense: string, convictionReviewID: number) {
    const index: number = this.ListConvictionReviewData.findIndex(g => g.ConvictionReviewId == convictionReviewID);
    if (index !== -1) {
      this.ListConvictionReviewData.splice(index, 1);
    }
    else {
      this.ListConvictionReviewData.push({ ConvictionReviewId: convictionReviewID, DriverLicenseNumber: driverLicense, DriverState: driverState });
    }
  }

  public deleteMultipleConvictionReview() {
    this.authService.currentUser.subscribe(g => {
      if (g != null) {
        var Account = new LoginModel();
        Account.Password = "xxxxxxxxx";
        Account.UserID = g.userID;
        this.userInfo = this.http.post<User>(this.baseUrl + 'login/Account/RefreshToken', Account);
        this.userInfo.subscribe(g => {
          localStorage.removeItem('currentUser');
          localStorage.setItem('currentUser', JSON.stringify(g));
          this.idleTimeoutSvc.resetTimer();
        });
      }
    });
    if (this.ListConvictionReviewData.length <= 0) {
      const dialogclientmsg = this.dialog.open(MessageDialogComponent, { width: '700px', data: { header: ' ', message: 'No rows have been selected. Please select the conviction review rows you wish to delete.', buttonOk: true, buttonClose: false, action: false, buttonOkLabel: 'Ok', buttonCloseLabel: 'Cancel' } });
      dialogclientmsg.afterClosed().subscribe(result => { });
    }
    else {
      const dialogRef = this.dialog.open(MessageDialogComponent, { width: '400px', data: { header: 'Confirmation', message: 'Are you sure you want to delete the record(s) ?', buttonOk: true, buttonClose: true, action: true, buttonOkLabel: 'Continue', buttonCloseLabel: 'Cancel' } });
      dialogRef.afterClosed().subscribe(result => {
        if (result == true && result != undefined) {
          this.spinner.show();
          this.http.post<boolean>(this.baseUrl + 'api/Data/DeleteConvictionReviews', this.ListConvictionReviewData).subscribe(
            data => {
              this.spinner.hide();
              if (data) {
                let Params = new HttpParams();
                Params = Params.append("jurisdiction", this.loginState);
                this.dataSourceObservable = this.http.get<ConvictionReviewTable[]>(this.baseUrl + 'api/Data/ConvictionReviews', { params: Params });
              }
            },
            error => {
              console.log("Error", error);
            }
          );
        }
      });
    }
  }

  public driverDetails(driverState, driverLicense) {
    //FIXED BUG 5186 EDIT BUTTON ON CONVICTION REVIEW
    var myurl = `${"/determineCountry"}/${driverState}/${driverLicense}`;

    //var myurl = `${"/detail"}/${driverState}/${driverLicense}`;
    this.router.navigateByUrl(myurl);
  };
}

@Component({
  selector: 'app-messageDialog-component',
  templateUrl: './messageDialog.component.html',
})

export class MessageDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<MessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Dialog) { }
  onNoClick(): void {
    this.dialogRef.close();
  }
}



export class RemoveConvictionReview {
  DriverLicenseNumber: string;
  DriverState: string;
  ConvictionReviewId: number;
}

export class DictConvictionReview {
  ConvictionReviewId: number;
  ConvictionReview: RemoveConvictionReview;
}


interface Dialog {
  header: string;
  message: string;
  buttonOk: boolean;
  buttonClose: boolean;
  action: boolean;
  buttonOkLabel: string;
  buttonCloseLabel: string;
}
