import { Component, Inject, ViewChild, OnInit, Input } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MatAccordion, fadeInContent, MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AddConvictionModel } from '../driverDetail/addConvitionModel';
import { UIDataService } from '../driverDetail/uiData.service';
import { Observable, Subscription, concat } from 'rxjs';
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { DriverDetailInformation, conviction, withdrawal, GeneralInfo, ResidenceInfo, LicenseDetail, MedicalDetail } from '../driverDetail/DriverDetailInformation';
import { UserAccountService } from '../account/userAccount.service';
import { MessageDialogComponent } from '../convictionReview/conReview.component';
import { share } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService, LoginModel } from '../_services/authentication.service';
import { MatInputModule } from '@angular/material/input';
import { User } from '../account/user';
import { SessionService } from '../_services/session.service';


@Component({
  selector: 'app-determineCountry-component',
  templateUrl: './determineCountry.component.html',
  styleUrls: ['./determineCountry.component.css']
})


export class determineCountryComponent implements OnInit {
  public isError: boolean;
  public errorMessage: string;
  public myurl: string;
  public driverObservable: Observable<DriverInfo>;
  public driverInfo = new DriverInfo();
  public driverInformation = new DriverDetailInformation();
  public generInfo = new GeneralInfo();

  constructor(private spinner: NgxSpinnerService, private idleTimeoutSvc: SessionService, private uiService: UIDataService, private UAS: UserAccountService, private router: Router, _http: HttpClient, public dialog: MatDialog, @Inject('BASE_URL') _baseUrl: string, private route: ActivatedRoute, private location: Location, private authService: AuthenticationService) {
    this.baseUrl = _baseUrl;
    this.http = _http;
  }

  http: HttpClient;
  baseUrl: string;
  DriverLicense: string;
  DriverState: string;
  LoginState: string;
  UserID: string;
  DataSource: string = "";
  userInfo: Observable<User>;
  @Input() matAutosizeMinRows: 3;
  @Input() matAutosizeMaxRows: 3;
  isConvictionCanada: Boolean;

  ngOnInit(): void {
    this.getDriverInfo();
  }

  public getDriverInfo() {
    this.spinner.show();
    var Account = new LoginModel();
    this.isError = false;
    this.errorMessage = "";
    this.spinner.show();
    this.authService.currentUser.subscribe(g => {
      if (g != null) {       
        Account.Password = "xxxxxxxxx";
        Account.UserID = g.userID;        
        this.userInfo = this.http.post<User>(this.baseUrl + 'login/Account/RefreshToken', Account);
        this.userInfo.subscribe(g => {
          localStorage.removeItem('currentUser');
          localStorage.setItem('currentUser', JSON.stringify(g));
          this.idleTimeoutSvc.resetTimer();
        });
      }
    });

    this.DriverLicense = this.route.snapshot.paramMap.get('license');
    this.DriverState = this.route.snapshot.paramMap.get('state');

    let Params = new HttpParams();
    Params = Params.append("state", this.DriverState);

    this.http.get<boolean>(this.baseUrl + 'api/Data/IsConvictionFromCanada', { params: Params }).subscribe(
      data => {
        if (data) {
          this.spinner.show();

         //GET DRIVER INFO FOR PAYLOAD CANADA DETAIL FUNCTIONALITY
          let Params = new HttpParams();         
          Params = Params.append("state", this.DriverState);
          Params = Params.append("driverLicense", this.DriverLicense);
          //Params = Params.append("User", Account.UserID);
          this.driverObservable = this.http.get<DriverInfo>(this.baseUrl + 'api/Data/GetDriverInformation', { params: Params }).pipe(share());
          this.driverObservable.subscribe(message => {            

            //// FIXED 5271 - Conviction Review Screen | CHECK AND SEE IF DRIVER INFO RETURN
            if (message != null) {
              /*format dbo*/
              var dobSelected = message.driverDob;

              //19260905
              var yyyy = dobSelected.substring(0, 4);
              var mm = dobSelected.substring(6, 4);
              var dd = dobSelected.substring(8, 6);

              var dob = mm + '-' + dd + '-' + yyyy;

              var firstName = message.driverLastName;
              var lastName = message.driverFirstName;
              var dlNo = this.DriverLicense;
              var dlState = this.DriverState;

              /*goto canada detail page.ts*/
              if (firstName == "") {
                firstName = "undefined";
              }

              this.spinner.hide();

              var myurl = `${"/canadaDetail"}/${dlState}/${dlNo}/${lastName}/${firstName}/${dob}`;
              this.router.navigateByUrl(myurl);
            }
            else {
              // FIXED 5271 - Conviction Review Screen              
              this.driverInformation.driverLicense = this.DriverLicense;
              this.driverInformation.driverState = this.DriverState;

              this.generInfo.driverLastName = "";
              this.generInfo.driverFirstName = "";

              this.driverInformation.generalInfo = this.generInfo;

              this.uiService.SendErrorMessage("Error: No Data exist in the FCWD DB");
              this.driverInformation.errorMessage = "Error: No Data exist in the FCWD DB";

              this.uiService.SendDriverInfoCanada(this.driverInformation);

              this.spinner.hide();

              var myurl1 = `${"/canadaDriverResponse"}`;
              this.router.navigateByUrl(myurl1);
            }         
          });
        }
        else {
          this.spinner.hide();

         //OTHER CONVICTIONS
          var myurl = `${"/detail"}/${this.DriverState}/${this.DriverLicense}`;
          this.router.navigateByUrl(myurl);
        }
      });
  }
}

export class DriverInfo {
  driverState: string;
  driverLicenseNumber: string;
  driverLastName: string;
  driverFirstName: string;
  driverMiddleName: string;
  driverSuffix: string;
  driverDob: string;
  driverSex: string;
  driverMailingAddress1: string;
  driverMailingAddress2: string;
  driverMailingCity: string;
  driverMailingState: string;
  driverMailingZipcode: string;
  driverMailingCounty: string;
  driverMailingCountry: string;
  driverInformationSource: string;
  driverInformationDatePosted: Date;
}
