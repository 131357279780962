import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { FAQComponent } from './faq/faq.component';
import { driverDetailComponent } from './driverDetail/driverDetail.component';
import { addConvictionComponent } from './driverDetail/addConviction/addConviction.component';
import { canAddConvictionComponent } from './canadaDriverDetail/canAddConviction/canAddConviction.component';
import { addWithdrawalComponent } from './driverDetail/addWithdrawal/addWithdrawal.component';
import { canAddWithdrawalComponent } from './canadaDriverDetail/canAddWithdrawal/canAddWithdrawal.component';
import { updateWithdrawalComponent } from './driverDetail/addWithdrawal/updateWithdrawal.component';
import { driverResponseRecordComponent } from './driverDetail/driverRecordResponse/driverResponseRecord.component';
import { addDriverInfoComponent } from './driverDetail/driverRecordResponse/addDriverInfo.component';
import { canAddDriverInfoComponent } from './canadaDriverDetail/canDriverRecordResponse/canAddDriverInfo.component';
import { UserAgreementComponent } from './account/userAgreement.component';
import { loginComponent } from './account/login.component';
import { signInComponent } from './account/signin.component';
import { Injectable } from '@angular/core';
import { AuthGuard } from './_guards/auth.guard';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { convictionReportComponent } from './reports/convictionTimelinessReport/convictionReport.component';
import { CanadaComponent } from './canada/canada.component';
import { canadaDriverDetailComponent } from './canadaDriverDetail/canadaDriverDetail.component';
import { canDriverResponseRecordComponent } from './canadaDriverDetail/canDriverRecordResponse/canDriverResponseRecord.component';
import { canUpdateWithdrawalComponent } from './canadaDriverDetail/canAddWithdrawal/canUpdateWithdrawal.component';
import { determineCountryComponent } from './determineCountry/determineCountry.component';

const routes: Routes = [
  { path: '', component: UserAgreementComponent, pathMatch: 'full' },
  { path: 'login', component: loginComponent },
  { path: 'signIn', component: signInComponent },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'faq', component: FAQComponent, canActivate: [AuthGuard] },
  { path: 'detail/:state/:license', component: driverDetailComponent, canActivate: [AuthGuard] },
  { path: 'addConviction', component: addConvictionComponent, canActivate: [AuthGuard] },
  { path: 'addWithdrawal', component: addWithdrawalComponent, canActivate: [AuthGuard] },
  { path: 'updateWithdrawal', component: updateWithdrawalComponent, canActivate: [AuthGuard] },
  { path: 'driverResponse', component: driverResponseRecordComponent, canActivate: [AuthGuard] },
  { path: 'addDriverInfo/:action', component: addDriverInfoComponent, canActivate: [AuthGuard] },
  { path: 'convictionReport', component: convictionReportComponent, canActivate: [AuthGuard] },
  { path: 'canada', component: CanadaComponent, canActivate: [AuthGuard] },
  { path: 'canadaDetail/:state/:license/:lastName/:firstName/:dob', component: canadaDriverDetailComponent, canActivate: [AuthGuard] },
  { path: 'canadaDriverResponse', component: canDriverResponseRecordComponent, canActivate: [AuthGuard] },
  { path: 'canAddConviction', component: canAddConvictionComponent, canActivate: [AuthGuard] },
  { path: 'canAddDriverInfo/:action', component: canAddDriverInfoComponent, canActivate: [AuthGuard] },
  { path: 'canAddWithdrawal', component: canAddWithdrawalComponent, canActivate: [AuthGuard] },
  { path: 'canUpdateWithdrawal', component: canUpdateWithdrawalComponent, canActivate: [AuthGuard] },
  { path: 'determineCountry/:state/:license', component: determineCountryComponent, canActivate: [AuthGuard] }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
